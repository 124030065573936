import React from "react";
import AppPagination from "./AppPagination";
import DropdownButton from "./DropdownButton";
import "./TableFooter.scss"; 

// Default style for DropdownButton
const defaultDropdownStyle = {
  boxShadow: "rgb(182 182 182) 0px 0px 1px 1px",
  color: "black",
  height: "40px",
  width: "110px",
  borderRadius: "10px",
  fontSize: "14px",
};

const TableFooter = ({
  setCurrentPage,
  currentPage,
  recordsPerPage,
  dataCount,
  rowLimits,
  dropdownText = "5 Rows",
  customDropdownStyle = defaultDropdownStyle,
}) => {
  return (
    <div className="table-footer">
      <div className="table-footer-pagination">
        <AppPagination
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          recordsPerPage={recordsPerPage}
          data_count={dataCount}
        />
      </div>
      <div className="table-footer-dropdown">
        <div className="table-footer-dropdown-text">Rows to display</div>
        <div className="table-footer-dropdown-button">
          <DropdownButton
            text={dropdownText}
            items={rowLimits}
            customStyle={customDropdownStyle}
          />
        </div>
      </div>
    </div>
  );
};

export default TableFooter;
