import React, { useState, useRef, useLayoutEffect, useEffect } from "react";
import { FaChevronDown } from "react-icons/fa";
import PropTypes from "prop-types";
import "./Accordion.scss";

const MAX_HEIGHT = 500; 
const EXTRA_OFFSET = 20; 

const Accordion = ({ title, content, isOpen: controlledIsOpen, onHeaderClick }) => {
  const [internalIsOpen, setInternalIsOpen] = useState(false);
  const isControlled = typeof controlledIsOpen === "boolean";
  const isOpen = isControlled ? controlledIsOpen : internalIsOpen;

  const innerRef = useRef(null);
  const [height, setHeight] = useState(0);

  useLayoutEffect(() => {
    if (isOpen && innerRef.current) {
      const measuredHeight = innerRef.current.scrollHeight;
      // Add extra offset for small content, but clamp to MAX_HEIGHT.
      setHeight(Math.min(measuredHeight + EXTRA_OFFSET, MAX_HEIGHT));
    } else {
      setHeight(0);
    }
  }, [isOpen, content]);

  useEffect(() => {
    if (!innerRef.current || !isOpen) return;
    const resizeObserver = new ResizeObserver((entries) => {
      if (entries[0]) {
        const measuredHeight = entries[0].target.scrollHeight;
        setHeight(Math.min(measuredHeight + EXTRA_OFFSET, MAX_HEIGHT));
      }
    });
    resizeObserver.observe(innerRef.current);
    return () => {
      resizeObserver.disconnect();
    };
  }, [isOpen]);

  const toggleAccordion = () => {
    if (onHeaderClick) {
      onHeaderClick();
    } else {
      setInternalIsOpen(!internalIsOpen);
    }
  };

  return (
    <div className="accordion">
      <div className={`accordion-title ${isOpen ? "open" : ""}`} onClick={toggleAccordion}>
        {title}
        <span className={`accordion-icon ${isOpen ? "rotate" : ""}`}>
          <FaChevronDown />
        </span>
      </div>
      <div
        className="accordion-content"
        style={{
          height: isOpen ? `${height}px` : "0px",
          overflowY: isOpen && height === MAX_HEIGHT ? "auto" : "hidden",
          transition: "height 0.3s ease",
        }}
      >
        <div className="accordion-content-inner" ref={innerRef}>
          {content}
        </div>
      </div>
    </div>
  );
};

Accordion.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
  isOpen: PropTypes.bool,
  onHeaderClick: PropTypes.func,
};

export default Accordion;
