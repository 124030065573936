import React from "react";
import PropTypes from "prop-types";
import TextInput from "./Questions/TextInput";
import RadioInput from "./Questions/RadioInput";
import DropdownInput from "./Questions/DropdownInput";
import DateTimeInput from "./Questions/DateTimeInput";
import DateInput from "./Questions/DateInput";
import TextAreaInput from "./Questions/TextAreaInput";
import CheckboxInput from "./Questions/CheckboxInput";
import NumberInput from "./Questions/NumberInput";
import FileInput from "./Questions/FileInput";
import { FaAsterisk } from "react-icons/fa";
import "./QuestionRenderer.scss";

const renderQuestionComponent = (
  question,
  selectedAnswer,
  handleInputChange,
  disabled,
  noBorder = false,
  parameter = null
) => {
  const { id, question_text, input_type, options, placeholder, required } =
    question;
  const labelWithRequired = required ? (
    <>
      <FaAsterisk
        style={{
          fontSize: "0.5em",
          color: "#ff8181",
          position: "relative",
          bottom: "5px",
        }}
      />{" "}
      {question_text}
    </>
  ) : (
    question_text
  );

  const commonProps = {
    key: id,
    label: labelWithRequired,
    name: id,
    value: selectedAnswer || "",
    placeholder,
    disabled,
    required,
    // Remove border & padding if noBorder flag is set.
    customStyles: noBorder ? { border: "none", padding: 0 } : {},
  };

  switch (input_type) {
    case "text":
      return (
        <TextInput
          {...commonProps}
          onChange={(e) => handleInputChange(id, e.target.value)}
        />
      );
    case "number":
      return (
        <NumberInput
          {...commonProps}
          onChange={(e) => handleInputChange(id, e.target.value)}
        />
      );
    case "textarea":
      return (
        <TextAreaInput
          {...commonProps}
          onChange={(e) => handleInputChange(id, e.target.value)}
        />
      );
    case "datetime":
      return (
        <DateTimeInput
          {...commonProps}
          onChange={(e) => handleInputChange(id, e.target.value)}
        />
      );
    case "date":
      return (
        <DateInput
          {...commonProps}
          onChange={(e) => handleInputChange(id, e.target.value)}
        />
      );
    case "checkbox":
      return (
        <CheckboxInput
          key={id}
          questionId={id}
          label={labelWithRequired}
          selectedAnswer={selectedAnswer || false}
          onCheckboxToggle={(value) => handleInputChange(id, value)}
          isReadOnly={disabled}
          customStyles={noBorder ? { border: "none", padding: 0 } : {}}
        />
      );
    case "radio":
      return (
        <RadioInput
          key={id}
          questionId={id}
          label={labelWithRequired}
          options={options}
          selectedAnswer={selectedAnswer}
          onOptionSelect={(option) => handleInputChange(id, option)}
          isReadOnly={disabled}
          customStyles={noBorder ? { border: "none", padding: 0 } : {}}
          parameter={parameter}
        />
      );
    case "dropdown":
      return (
        <DropdownInput
          key={id}
          label={labelWithRequired}
          options={options}
          selectedOption={selectedAnswer}
          onOptionSelect={(option) => handleInputChange(id, option)}
          disabled={disabled}
          customStyles={noBorder ? { border: "none", padding: 0 } : {}}
          parameter={parameter}
        />
      );
    case "file":
      return (
        <FileInput
          key={id}
          label={labelWithRequired}
          onFileSelect={(file) => handleInputChange(id, file)}
          customStyles={noBorder ? { border: "none", padding: 0 } : {}}
          disabled={disabled}
        />
      );
    default:
      return <p key={id}>Unsupported question type: {input_type}</p>;
  }
};

const QuestionRenderer = ({ question, selectedAnswers, handleInputChange }) => {
  const isDependent = question.dependent_on !== null;
  const parentAnswer = isDependent
    ? selectedAnswers[question.dependent_on]
    : null;
  const disabled = isDependent && !parentAnswer;

  // Determine if this question has children.
  const hasChildren =
    question.children &&
    typeof question.children === "object" &&
    Object.keys(question.children).length > 0;
  const widthClass =
    hasChildren ||
    question.input_type === "radio" ||
    (question.input_type === "textarea" && window.innerWidth <= 1000)
      ? "full-width"
      : "half-width";

  // Compute parameter: if the question's options type is "api/parameter", then pass parentAnswer.
  const parameter =
    question.options && question.options.type === "api/parameter"
      ? parentAnswer
      : null;

  // Render the main component, passing noBorder flag if there are children.
  const mainComponent = renderQuestionComponent(
    question,
    selectedAnswers[question.id],
    handleInputChange,
    disabled,
    hasChildren,
    parameter
  );

  // Handle children using the updated structure:
  // - Always visible children from children.always_visible
  // - Option-dependent children from children.option_dependent keyed by the parent's answer
  let childrenComponents = null;
  if (hasChildren) {
    let childrenArray = [];

    if (Array.isArray(question.children.always_visible)) {
      childrenArray = childrenArray.concat(question.children.always_visible);
    }

    if (
      question.children.option_dependent &&
      typeof question.children.option_dependent === "object"
    ) {
      const parentSelected = selectedAnswers[question.id];
      // Convert the parent's answer to a string to match children keys for both types.
      const parentKey =
        parentSelected !== undefined && parentSelected !== null
          ? String(parentSelected)
          : null;

      if (
        parentKey &&
        Array.isArray(question.children.option_dependent[parentKey])
      ) {
        childrenArray = childrenArray.concat(
          question.children.option_dependent[parentKey]
        );
      }
    }

    if (childrenArray.length > 0) {
      childrenComponents = childrenArray.map((childQuestion) => (
        <QuestionRenderer
          key={childQuestion.id}
          question={childQuestion}
          selectedAnswers={selectedAnswers}
          handleInputChange={handleInputChange}
        />
      ));
    }
  }

  if (childrenComponents) {
    return (
      <div className={`question-wrapper ${widthClass}`}>
        <div className="question-main">{mainComponent}</div>
        <div className="question-children">{childrenComponents}</div>
      </div>
    );
  }

  return (
    <div className={`question-container ${widthClass}`}>{mainComponent}</div>
  );
};

QuestionRenderer.propTypes = {
  question: PropTypes.object.isRequired,
  selectedAnswers: PropTypes.object.isRequired,
  handleInputChange: PropTypes.func.isRequired,
};

export default QuestionRenderer;
