import React, { useState, useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import { FaPlusCircle, FaSearch, FaUser } from "react-icons/fa";
import "./PeopleAddSearch.scss";

const PeopleAddSearch = ({
  label,
  onSelect,
  onSearch, // Function: (searchTerm, page) => { ... }
  items,
  totalItemsCount,
  customStyles = {},
  disabled = false,
  placeholder = "Search People",
}) => {
  const [isActive, setIsActive] = useState(false); // false = button mode; true = search mode
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [dropdownItems, setDropdownItems] = useState(items || []);
  const dropdownRef = useRef(null);
  const listRef = useRef(null);
  const inputRef = useRef(null);

  // Update local dropdown items when parent passes new items.
  useEffect(() => {
    setDropdownItems(items);
  }, [items]);

  // When active and no items are loaded, invoke onSearch.
  useEffect(() => {
    if (isActive && dropdownItems.length === 0 && onSearch) {
      setPage(1);
      onSearch("", 1);
    }
  }, [isActive, dropdownItems, onSearch]);

  const handleInputChange = (e) => {
    const newSearchTerm = e.target.value;
    setSearchTerm(newSearchTerm);
    setPage(1);
    if (onSearch) {
      onSearch(newSearchTerm, 1);
    }
  };

  const loadMoreItems = useCallback(async () => {
    if (loading) return;
    if (dropdownItems.length < totalItemsCount) {
      setLoading(true);
      const nextPage = page + 1;
      setPage(nextPage);
      await onSearch(searchTerm, nextPage);
      setLoading(false);
    }
  }, [loading, dropdownItems, totalItemsCount, page, searchTerm, onSearch]);

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (scrollTop + clientHeight >= scrollHeight - 5) {
      loadMoreItems();
    }
  };

  // Close search mode on outside click.
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setIsActive(false);
        setSearchTerm("");
        setPage(1);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () =>
      document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  // Focus the input when search mode is activated.
  useEffect(() => {
    if (isActive && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isActive]);

  const handleItemClick = (item) => {
    if (onSelect) {
      onSelect(item);
    }
    setIsActive(false);
    setSearchTerm("");
    setPage(1);
  };

  return (
    <div
      className={`people-add-search ${disabled ? "people-add-search--disabled" : ""}`}
      ref={dropdownRef}
      style={customStyles.container}
    >
      {!isActive ? (
        <div
          className="people-add-search-add-button"
          onClick={() => setIsActive(true)}
          style={customStyles.button}
        >
          <FaPlusCircle className="people-add-search-add-button-icon" />
          <span className="people-add-search-add-button-label">{label}</span>
        </div>
      ) : (
        <div className="people-add-search-input-container" style={customStyles.inputContainer}>
          <input
            type="text"
            placeholder={placeholder}
            value={searchTerm}
            onChange={handleInputChange}
            className="people-add-search-input-container-input"
            ref={inputRef}
          />
          {isActive && (
            <div className="people-add-search-dropdown-menu" style={customStyles.menu}>
              <div
                className="people-add-search-dropdown-menu-list"
                onScroll={handleScroll}
                ref={listRef}
              >
                <div className="people-add-search-dropdown-menu-list-search-container">
                  <FaSearch className="people-add-search-dropdown-menu-list-search-container-icon" />
                  <input
                    type="text"
                    placeholder={placeholder}
                    value={searchTerm}
                    onChange={handleInputChange}
                    className="people-add-search-dropdown-menu-list-search-container-input"
                  />
                </div>
                {dropdownItems && dropdownItems.length ? (
                  dropdownItems.map((item) => (
                    <div
                      key={item.id}
                      className="people-add-search-dropdown-menu-list-item"
                      onMouseDown={() => handleItemClick(item)}
                      style={customStyles.item}
                    >
                      <div className="people-add-search-dropdown-menu-list-item-avatar">
                        {item.short_name ? item.short_name : <FaUser />}
                      </div>
                      <div className="people-add-search-dropdown-menu-list-item-text">
                        {item.full_name}
                      </div>
                    </div>
                  ))
                ) : !loading ? (
                  <div className="people-add-search-dropdown-menu-list-item people-add-search-dropdown-menu-list-item--no-items">
                    No items found
                  </div>
                ) : null}
                {dropdownItems.length < totalItemsCount && !loading && (
                  <div
                    className="people-add-search-dropdown-menu-list-item people-add-search-dropdown-menu-list-item--view-more"
                    onClick={loadMoreItems}
                  >
                    View More
                  </div>
                )}
                {loading && (
                  <div className="people-add-search-dropdown-menu-list-item people-add-search-dropdown-menu-list-item--loading">
                    Loading...
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

PeopleAddSearch.propTypes = {
  label: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  items: PropTypes.array,
  totalItemsCount: PropTypes.number.isRequired,
  customStyles: PropTypes.object,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
};

export default PeopleAddSearch;
