import React, { useContext, useEffect, useState, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./IncidentReportForm.scss";
import FormLayout from "../../../common/components/Form/FormLayout";
import {
  getFormSections,
  getFormSectionQuestions,
  submitSectionResponse,
} from "../services/api.services";
import { AuthContext } from "../../../App";
import toast from "react-hot-toast";
import Loader from "../../../common/components/Loader";

const IncidentReportForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = useContext(AuthContext);

  const [sectionData, setSectionData] = useState([]);
  const [sectionQuestionData, setSectionQuestionData] = useState([]);
  const [selectedSection, setSelectedSection] = useState(null);
  const [incidentFormId, setIncidentFormId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isLinear, setIsLinear] = useState(false);

  const header = useMemo(
    () => ({
      Authorization: "Token " + state.token,
    }),
    [state.token]
  );

  useEffect(() => {
    if (location.state && location.state.incidentFormId) {
      setIncidentFormId(location.state.incidentFormId);
      setIsLinear(location.state.isLinear || false);
      fetchFormSections(location.state.incidentFormId);
    } else {
      toast.error("No incident data available. Please try again.");
      navigate("/incident/user");
    }
  }, [location.state]);

  const fetchFormSections = async (incidentId) => {
    try {
      const response = await getFormSections(header, incidentId);
      if (response.status === 200 && response.data?.results) {
        setSectionData(response.data.results);
        if (response.data.results.length > 0) {
          // Load the first section by default.
          handleSectionSelect(response.data.results[0]);
        }
      } else {
        toast.error("Failed to fetch sections: Invalid response format.");
      }
    } catch (error) {
      toast.error("Error fetching sections: " + error.message);
    }
  };

  const handleSectionSelect = async (section) => {
    if (isLinear && sectionData.length > 0) {
      let lastSubmittedPosition = 0;
      sectionData.forEach((sec) => {
        if (sec.is_submitted && sec.position > lastSubmittedPosition) {
          lastSubmittedPosition = sec.position;
        }
      });
      const allowedNextPosition = lastSubmittedPosition + 1;
      if (!section.is_submitted && section.position !== allowedNextPosition) {
        toast.error("Please complete previous sections before moving forward.");
        return;
      }
    }
    
    setSelectedSection(section);
    setLoading(true);
    try {
      const response = await getFormSectionQuestions(header, section.id);
      if (response.status === 200 && response.data?.form_section?.questions) {
        setSectionQuestionData(response.data.form_section.questions);
      } else {
        toast.error("Failed to fetch questions: Invalid response format.");
      }
    } catch (error) {
      toast.error("Error fetching questions: " + error.message);
    } finally {
      setLoading(false);
    }
  };
  
  const handleResponseSubmission = async (selectedAnswers) => {
    const answersArray = Object.keys(selectedAnswers).map((questionId) => ({
      question: questionId,
      data: { answer: selectedAnswers[questionId] },
    }));

    const payload = {
      incident_id: incidentFormId,
      form_section: selectedSection.id,
      answers: answersArray,
    };
    try {
      const response = await submitSectionResponse(header, JSON.stringify(payload));
      if (response.status === 200) {
        toast.success("Submitted successfully");
        const updatedResponse = await getFormSections(header, incidentFormId);
        // Mark current section as submitted.
        selectedSection.is_submitted = true;
        if (updatedResponse.status === 200 && updatedResponse.data?.results) {
          setSectionData(updatedResponse.data.results);
          const currentPosition = selectedSection.position;
          const nextSection = updatedResponse.data.results
            .filter((section) => section.position > currentPosition)
            .sort((a, b) => a.position - b.position)[0];

          if (nextSection) {
            handleSectionSelect(nextSection);
          } else {
            navigate("/incident/user");
          }
        }
      }
      return response;
    } catch (error) {
      toast.error("Submission error: " + error.message);
      console.error("Submission error:", error);
      throw error;
    }
  };

  if (!sectionData.length) {
    return (
      <div className="incident-report-form">
        <div className="incident-report-form-title">
          <div className="incident-report-form-title-text">
            Incident Report Form
          </div>
        </div>
        <div
          className="incident-report-form-loader"
          style={{
            minHeight: "calc(100vh - 100px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loader customStyle={{ background: "transparent" }} />
        </div>
      </div>
    );
  }

  return (
    <div className="incident-report-form">
      <div className="incident-report-form-title">
        <div className="incident-report-form-title-text">
          Incident Report Form
        </div>
      </div>
      <FormLayout
        sectionData={sectionData}
        questionData={sectionQuestionData}
        selectedSection={selectedSection}
        onSectionSelect={handleSectionSelect}
        handleResponseSubmission={handleResponseSubmission}
        isLinear={isLinear}
        loading={loading}
      />
    </div>
  );
};

export default IncidentReportForm;
