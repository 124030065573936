import React from "react";
import PropTypes from "prop-types";
import { FaCheck } from "react-icons/fa";
import "./FormNav.scss";

const FormNav = ({ sections, selectedSection, onSectionSelect, isLinear }) => {
  return (
    <div className="form-nav">
      {sections.map((section, index) => (
        <div
          key={section.id}
          className={`form-nav-item ${selectedSection?.id === section.id ? "active" : ""}`}
          onClick={() => onSectionSelect(section)}
        >
          <div
            className={`form-nav-item-number ${selectedSection?.id === section.id ? "active" : ""} ${
              section.is_submitted ? "submitted" : ""
            }`}
          >
            {section.is_submitted ? (
              <FaCheck />
            ) : (
              index + 1
            )}
          </div>
          <div className="form-nav-item-text">
            <div className="form-nav-item-text-info">Step {index + 1} of {sections.length}</div>
            <div className="form-nav-item-text-name">{section.name}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

FormNav.propTypes = {
  sections: PropTypes.array.isRequired,
  selectedSection: PropTypes.object,
  onSectionSelect: PropTypes.func.isRequired,
  isLinear: PropTypes.bool,
};

export default FormNav;
