import React, { useState, useEffect } from "react";
import UserIncidentActionsSidebar from "./UserIncidentActionsSidebar";
import UserIncidentForm from "./UserIncidentForm";
// import IncidentActivity from "./IncidentActivity/IncidentActivity";
import UserIncidentActivity from "./UserIncidentActivity/UserIncidentActivity";
// import IncidentActionPlanning from "./IncidentActionPlanning";


import "./UserIncidentActions.scss";
const UserIncidentActions = ({header, incidentId}) => {
  const [activeNav, setActiveNav] = useState("incidentForm");
  const handleNavChange = (nav) => setActiveNav(nav);

  const renderContent = () => {
    if (activeNav === "incidentForm") {
      return <UserIncidentForm header={header} incidentId={incidentId}/>;
    }
    if (activeNav === "activity") {
      return <UserIncidentActivity header={header} incidentId={incidentId}/>;
    }
    if (activeNav === "actionPlanning") {
      // return <IncidentActionPlanning />;
    }
  };

  return (
    <div className="user-incident-actions">
      {/* Sidebar Navigation */}
      <UserIncidentActionsSidebar
        activeNav={activeNav}
        onNavChange={setActiveNav}
      />

      {/* Main Content */}
      <div className="user-incident-actions-content">
        <div className="user-incident-actions-content-header">
          <div className="user-incident-actions-content-header-title">
            {activeNav === "incidentForm"
              ? "Incident Form"
              : activeNav === "activity"
              ? "Activity"
              : "Action Planning"}
          </div>
        </div>

        {/* Dynamic Content */}
        <div className="user-incident-actions-content-body">{renderContent()}</div>
      </div>
    </div>
  );
};

export default UserIncidentActions;
