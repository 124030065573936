import React from "react";
import PropTypes from "prop-types";
import "./FormLayout.scss";
import FormNav from "./FormNav";
import SectionContent from "./SectionContent";

const FormLayout = ({
  sectionData,
  questionData,
  selectedSection,
  onSectionSelect,
  handleResponseSubmission,
  isLinear,
  loading, 
}) => {
  return (
    <div className="form-layout">
      <div className="form-layout-nav">
        <FormNav
          sections={sectionData}
          selectedSection={selectedSection}
          onSectionSelect={onSectionSelect}
          isLinear={isLinear}
        />
      </div>
      <div className="form-layout-content">
        <SectionContent
          sections={sectionData}
          selectedSection={selectedSection}
          questions={questionData}
          handleResponseSubmission={handleResponseSubmission}
          onSectionSelect={onSectionSelect}
          isLinear={isLinear}
          loading={loading} 
        />
      </div>
    </div>
  );
};

FormLayout.propTypes = {
  sectionData: PropTypes.array.isRequired,
  questionData: PropTypes.array.isRequired,
  selectedSection: PropTypes.object,
  onSectionSelect: PropTypes.func.isRequired,
  handleResponseSubmission: PropTypes.func.isRequired,
  isLinear: PropTypes.bool,
  loading: PropTypes.bool,
};

export default FormLayout;
