import React, { useState } from "react";
import { FaChevronDown } from "react-icons/fa";
import "./DropdownButton.scss";
import "../../theme/_buttons.scss";

const DropdownButton = ({
  text,
  items,
  customStyle = {}
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownText, setDropdownText] = useState(text);


  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleItemClick = (item) => {
    setIsOpen(false); 
    setDropdownText(item.label)
    item.handleClick(); 
  };

  return (
    <div className="dropdown-button">
      <div
        className="dropdown-button__toggle"
        style={customStyle}
        onClick={toggleDropdown}
      >
        {dropdownText}
        <FaChevronDown className={`chevron-icon ${isOpen ? "rotate" : ""}`} />
      </div>
      <ul className={`dropdown-button__list ${isOpen ? "open" : ""}`} >
        {items.map((item, index) => (
          <li
            key={index}
            className="dropdown-button__list-item"
            onClick={() => handleItemClick(item)}
          >
            {item.label}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DropdownButton;
