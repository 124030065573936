import React from "react";
import PropTypes from "prop-types";
import useOptions from "../Helpers/useOptions";
import "./RadioInput.scss";

const RadioInput = ({
  questionId,
  label,
  options,
  selectedAnswer,
  onOptionSelect,
  isReadOnly,
  parameter = null, 
  customStyles = {}
}) => {
  const { optionsData, loading } = useOptions(options, parameter);

  if (loading) {
    return <p>Loading options...</p>;
  }

  const selectedOption = optionsData.find((option) => option.id === selectedAnswer);

  return (
    <div className={`radio-input-question ${isReadOnly ? "disabled" : ""}`} style={customStyles.container}>
      <div className="radio-input-question_text" style={customStyles.label}>{label}</div>
      <div className="radio-input-question_options multiple_choice" style={customStyles.options}>
        {optionsData.map((option, index) => (
          <div key={index} className="form__radio-group" style={customStyles.item}>
            <input
              id={`${questionId}_${index}`}
              type="radio"
              className="form__radio-input"
              name={questionId}
              value={option.id}
              checked={selectedOption ? selectedOption.id === option.id : false}
              onChange={() => {
                if (!isReadOnly) {
                  onOptionSelect(option.id);
                }
              }}
              disabled={isReadOnly}
            />
            <label htmlFor={`${questionId}_${index}`} className="form__radio-label">
              <span className="form__radio-button"></span>
              {option.value}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

RadioInput.propTypes = {
  questionId: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.oneOfType([
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      values: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
    }),
    PropTypes.array,
  ]).isRequired,
  selectedAnswer: PropTypes.string,
  onOptionSelect: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool,
  parameter: PropTypes.string,
  customStyles: PropTypes.object,
};

export default RadioInput;
