import React, { useState } from "react";
import ButtonTabs from "../../../../common/components/ButtonTabs";
import Comments from "./Comments";
import Attachments from "./Attachments";
import History from "./History";

const tabItems = ["Comments", "Attachments", "History"];

const UserIncidentActivity = ({ header, incidentId }) => {
  const [selectedTab, setSelectedTab] = useState(tabItems[0]);

  return (
    <div>
      <ButtonTabs
        items={tabItems}
        activeTab={selectedTab}
        onTabChange={setSelectedTab}
      />

      <div className="activity-content">
        {selectedTab === "Comments" && <Comments header={header} incidentId={incidentId}/>}
        {selectedTab === "Attachments" && <Attachments header={header} incidentId={incidentId}/>}
        {selectedTab === "History" && <History header={header} incidentId={incidentId}/>}
      </div>
    </div>
  );
};

export default UserIncidentActivity;
