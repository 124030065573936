import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaExclamationTriangle } from "react-icons/fa";
import toast from "react-hot-toast";
import PopupModal from "../../../learning/admin/components/PopupModal";
import SearchBox from "../../../learning/user/components/SearchBox";
import Table from "../../../common/components/Table";
import AppPagination from "../../../learning/admin/components/AppPagination";
import "../../../theme/_buttons.scss";
import "./AdminIncidentReports.scss";

const AdminIncidentReports = () => {
  const navigate = useNavigate();
  const [isRemove, setIsRemove] = useState(false);
  const [key, setKey] = useState(false);
  const [popupType, setPopupType] = useState("");
  const [checkedData, setCheckedData] = useState([]);
  const [data, setData] = useState([]);
  const itemClass = checkedData.length > 0 ? "active" : "inactive";
  const [requestNames, setRequestNames] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [openDropdownId, setOpenDropdownId] = useState(null);

  const rows = [
    {
      "#": "1",
      "Incident ID": "INC-001",
      "Incident Name": "Fall",
      "Date of the Event": "19 Jul 2024, 2:22 pm",
      "Reporting Manager": "Ellen Patrick",
      Priority: "High",
      Status: "Open",
      Name: "Hailey John",
      Age: 66,
      Issue: "Fall",
      "Risk Score": 2,
      Details:
        "The individual was walking in the hallway near their room when they suddenly lost balance and fell to the floor. The individual was found on the floor, sitting up but complaining of slight discomfort in the knee. Staff immediately approached to assess the situation and provide assistance.",
      "Raised By": "Jenny Williams",
      "Immediate Actions":
        "The individual was assessed for injuries immediately after the fall. No visible injuries were noted initially. Vital signs were checked, and the individual appeared stable with no signs of shock.",
    },
    {
      "#": "2",
      "Incident ID": "INC-002",
      "Incident Name": "Injury",
      "Date of the Event": "21 Jul 2024, 9:02 am",
      "Reporting Manager": "Will Thomas",
      Priority: "Low",
      Status: "In progress",
      Name: "John Doe",
      Age: 34,
      Issue: "Injury",
      "Risk Score": 1,
      Details:
        "The individual sustained an injury while playing sports. The injury was a minor sprain, and the individual was able to walk with assistance.",
      "Raised By": "Jane Smith",
      "Immediate Actions":
        "The individual was given first aid and taken to the hospital for further evaluation.",
    },
    {
      "#": "3",
      "Incident ID": "INC-003",
      "Incident Name": "Fire",
      "Date of the Event": "22 Jul 2024, 5:45 pm",
      "Reporting Manager": "Sarah Johnson",
      Priority: "Critical",
      Status: "Closed",
      Name: "Michael Brown",
      Age: 40,
      Issue: "Fire",
      "Risk Score": 5,
      Details:
        "A small fire broke out in the kitchen due to an electrical fault. No injuries were reported, and the damage was minimal.",
      "Raised By": "David Lee",
      "Immediate Actions":
        "The fire alarm was activated, and the building was evacuated. Firefighters were called, and the fire was extinguished within 30 minutes.",
    },
    {
      "#": "4",
      "Incident ID": "INC-004",
      "Incident Name": "Medical Emergency",
      "Date of the Event": "23 Jul 2024, 11:15 am",
      "Reporting Manager": "Emily Davis",
      Priority: "High",
      Status: "Open",
      Name: "Laura Wilson",
      Age: 52,
      Issue: "Medical Emergency",
      "Risk Score": 4,
      Details:
        "The individual collapsed suddenly and was unresponsive. Medical staff were immediately called, and CPR was administered until the ambulance arrived.",
      "Raised By": "Mark Anderson",
      "Immediate Actions":
        "The individual was given CPR and rushed to the hospital.",
    },
  ];

  const headers = [
    "#",
    "Incident ID",
    "Incident Name",
    "Date of the Event",
    "Reporting Manager",
    "Priority",
    "Status",
  ];

  var recordsPerPage = 5;
  const [pageNo, setPageNo] = useState(1);
  const [dataCount, setDataCount] = useState(5);

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const handleNavigate = () => {
    navigate("/incident/admin/incident-report-form/");
  };

  const handleFileDownload = (fileUrl, fileName) => {
    try {
      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // toast.success("File downloaded successfully!");
    } catch (error) {
      toast.error("Failed to download the file.");
    }
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handleRemoveData = async () => {
    if (checkedData.length === 0) {
      toast.error("No items selected for deletion.");
      return;
    }
    toast.success("Data removed successfully.");
    setCheckedData([]);
    setIsRemove(false);
    setKey(!key);
  };

  const handleCancelClick = () => {
    setCheckedData([]);
    setIsRemove(false);
  };

  const handleClearSearch = () => {
    setSearchKey("");
    setKey(!key);
  };

  const handleRequestSearch = (searchText) => {
    setSearchKey(searchText);
    return requestNames;
  };
  const handleClearRequestSearch = () => {
    setSearchKey("");
    setKey(!key);
  };
  const handleRequestValueClick = (requestName) => {
    setSearchKey(requestName);
  };

  const formatFileName = (url) => {
    return url.split("/").pop().split("?")[0];
  };

  const renderIncidentTooltip = (row) => (
    <div className="incident-tooltip">
      <div className="incident-tooltip-container">
        <div className="incident-tooltip-container-header">
          <div className="incident-tooltip-container-header-row">
            <div className="incident-tooltip-container-header-row-item">
              <div className="incident-tooltip-container-header-row-item-value">
                {row.Name}
              </div>
            </div>
            <div className="incident-tooltip-container-header-row-item">
              <div className="incident-tooltip-container-header-row-item-key">
                Age
              </div>
              :
              <div className="incident-tooltip-container-header-row-item-value">
                {row.Age}
              </div>
            </div>
          </div>
          <div className="incident-tooltip-container-header-row">
            <div className="incident-tooltip-container-header-row-item">
              <div className="incident-tooltip-container-header-row-item-key">
                Issue
              </div>
              :
              <div className="incident-tooltip-container-header-row-item-value">
                {row.Issue}
              </div>
            </div>
            <div className="incident-tooltip-container-header-row-item">
              <div className="incident-tooltip-container-header-row-item-key">
                Risk Score
              </div>
              :
              <div className="incident-tooltip-container-header-row-item-value">
                {row["Risk Score"]}
              </div>
            </div>
          </div>
        </div>

        <div className="incident-tooltip-container-body">
          <div className="incident-tooltip-container-body-details">
            <div className="incident-tooltip-container-body-details-title">
              Details
            </div>
            <div className="incident-tooltip-container-body-details-text">
              {row.Details}
            </div>
          </div>
          <div className="incident-tooltip-container-body-details">
            <div className="incident-tooltip-container-body-details-title">
              Raised By
            </div>
            <div className="incident-tooltip-container-body-details-text">
              {row["Raised By"]}
            </div>
          </div>
          <div className="incident-tooltip-container-body-details">
            <div className="incident-tooltip-container-body-details-title">
              Reporting Manager
            </div>
            <div className="incident-tooltip-container-body-details-text">
              {row["Reporting Manager"]}
            </div>
          </div>
          <div className="incident-tooltip-container-body-details">
            <div className="incident-tooltip-container-body-details-title">
              Immediate Actions
            </div>
            <div className="incident-tooltip-container-body-details-text">
              {row["Immediate Actions"]}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="admin_incident_reports">
      <div className="admin_incident_reports-title">Incident Reports</div>
      <div className="admin_incident_reports-body">
        <div className="admin_incident_reports-body-tab-content">
          <div className="admin_incident_reports-body-tab-content-top">
            {/* <button
              className="primary-button"
              style={{ width: "150px" }}
              onClick={() => handleNavigate()}
            >
              <FaExclamationTriangle />
              Report Incident
            </button> */}

            <div className="admin_incident_reports-body-tab-content-top-search_box">
              <SearchBox
                searchData={[]}
                onSearch={handleRequestSearch}
                onValueClick={handleRequestValueClick}
                onClearSearch={handleClearRequestSearch}
                useDynamicSearch={true}
              />
            </div>
          </div>

          <Table
            headers={headers}
            rows={rows}
            tooltip={true}
            renderTooltipContent={renderIncidentTooltip}
            getRowNavigationPath={(row) => {
              return {
                pathname: "/incident/admin/incident-details",
                state: { incidentFormId: row["Incident ID"] },
              };
            }}
          />
          {dataCount > recordsPerPage && (
            <AppPagination
              setCurrentPage={setPageNo}
              currentPage={pageNo}
              recordsPerPage={recordsPerPage}
              data_count={dataCount}
            />
          )}
        </div>
      </div>

      {/* Popup Modal */}
      {isRemove && (
        <PopupModal setIsOpen={setIsRemove}>
          <div className="unenroll_confirm_modal-body">
            <div className="unenroll_confirm_modal-body-title">
              Remove Incident(s)
            </div>
            <div className="unenroll_confirm_modal-body-text">
              Are you sure you want to remove selected{" "}
              {checkedData.length < 2 ? "incident" : "incidents"}?
            </div>
            <div className="unenroll_confirm_modal-body-buttons">
              <button
                className="primary-button"
                onClick={() => {
                  handleRemoveData();
                }}
              >
                Confirm
              </button>
              <button className="secondary-button" onClick={handleCancelClick}>
                Cancel
              </button>
            </div>
          </div>
        </PopupModal>
      )}
    </div>
  );
};

export default AdminIncidentReports;
