import React, { useState, useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import { FaChevronDown, FaSearch, FaUser } from "react-icons/fa";
import "./PeopleSearchDropdown.scss";

const PeopleSearchDropdown = ({
  label,
  value,
  items,
  onSelect,
  onSearch, // Function: (searchTerm, page) => { ... }
  totalItemsCount,
  customStyles = {},
  disabled = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [dropdownItems, setDropdownItems] = useState(items || []);
  const [selectedColor, setSelectedColor] = useState("");
  const dropdownRef = useRef(null);
  const listRef = useRef(null);
  const inputRef = useRef(null);

  const getRandomColor = () => {
    const colors = [
      "#FF5733",
      "#33FF57",
      "#3357FF",
      "#FF33A1",
      "#A133FF",
      "#FFB533",
      "#33FFF5",
      "#FF5733",
      "#FF33F5",
      "#33FFB5",
      "#5733FF",
      "#33A1FF",
      "#A1FF33",
      "#FF5733",
      "#33FF57",
      "#FF33A1",
      "#A133FF",
      "#FF5733",
      "#33FF57",
      "#3357FF",
    ];
    return colors[Math.floor(Math.random() * colors.length)];
  };

  useEffect(() => {
    if (value && !selectedColor) {
      setSelectedColor(getRandomColor());
    }
  }, [value, selectedColor]);

  // Update local items when parent passes new items.
  useEffect(() => {
    setDropdownItems(items);
  }, [items]);

  // When dropdown is opened and no items are loaded, invoke onSearch.
  useEffect(() => {
    if (isOpen && dropdownItems.length === 0 && onSearch) {
      setPage(1);
      onSearch("", 1);
    }
  }, [isOpen, dropdownItems, onSearch]);

  const handleInputChange = (e) => {
    const newSearchTerm = e.target.value;
    setSearchTerm(newSearchTerm);
    setPage(1);
    if (onSearch) {
      onSearch(newSearchTerm, 1);
    }
  };

  const loadMoreItems = useCallback(async () => {
    if (loading) return;
    if (dropdownItems.length < totalItemsCount) {
      setLoading(true);
      const nextPage = page + 1;
      setPage(nextPage);
      await onSearch(searchTerm, nextPage);
      setLoading(false);
    }
  }, [loading, dropdownItems, totalItemsCount, page, searchTerm, onSearch]);

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (scrollTop + clientHeight >= scrollHeight - 5) {
      loadMoreItems();
    }
  };

  const toggleDropdown = () => {
    if (!disabled) {
      setIsOpen((prev) => !prev);
    }
  };

  // Close dropdown on outside click.
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () =>
      document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  // Focus the search input when dropdown opens.
  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  const handleItemClick = (item) => {
    if (onSelect) {
      onSelect(item);
    }
    setIsOpen(false);
    setSearchTerm("");
    setPage(1);
  };

  return (
    <div
      className={`people-search-dropdown ${
        disabled ? "people-search-dropdown--disabled" : ""
      }`}
      ref={dropdownRef}
      style={customStyles.container}
    >
      {label && (
        <label
          className="people-search-dropdown-label"
          style={customStyles.label}
        >
          {label}:
        </label>
      )}
      <div
        className="people-search-dropdown-header"
        onClick={toggleDropdown}
        style={customStyles.header}
      >
        <div className="people-search-dropdown-header-selected">
          <div
            className="people-search-dropdown-header-selected-avatar"
            style={{
              backgroundColor: value ? selectedColor : "#cccccc",
            }}
          >
            {value ? value.short_name : <FaUser />}
          </div>
          <div className="people-search-dropdown-header-selected-text">
            {value ? value.full_name : "Unassigned"}
          </div>
        </div>
        <FaChevronDown
          className={`people-search-dropdown-header-icon ${
            isOpen ? "people-search-dropdown-header-icon--rotate" : ""
          }`}
        />
      </div>
      {isOpen && (
        <div
          className="people-search-dropdown-menu"
          style={customStyles.menu}
        >
          <div
            className="people-search-dropdown-menu-list"
            onScroll={handleScroll}
            ref={listRef}
          >
            <div className="people-search-dropdown-menu-list-search-container">
              <FaSearch className="people-search-dropdown-menu-list-search-container-icon" />
              <input
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={handleInputChange}
                className="people-search-dropdown-menu-list-search-container-input"
                ref={inputRef}
              />
            </div>
            {dropdownItems && dropdownItems.length ? (
              dropdownItems.map((item) => (
                <div
                  key={item.id}
                  className="people-search-dropdown-menu-list-item"
                  onMouseDown={() => handleItemClick(item)}
                  style={customStyles.item}
                >
                  <div
                    className="people-search-dropdown-menu-list-item-avatar"
                    style={{
                      backgroundColor: getRandomColor(),
                    }}
                  >
                    {item.short_name}
                  </div>
                  <div className="people-search-dropdown-menu-list-item-text">
                    {item.full_name}
                  </div>
                </div>
              ))
            ) : !loading ? (
              <div className="people-search-dropdown-menu-list-item people-search-dropdown-menu-list-item--no-items">
                No items found
              </div>
            ) : null}
            {dropdownItems.length < totalItemsCount && !loading && (
              <div
                className="people-search-dropdown-menu-list-item people-search-dropdown-menu-list-item--view-more"
                onClick={loadMoreItems}
              >
                View More
              </div>
            )}
            {loading && (
              <div className="people-search-dropdown-menu-list-item people-search-dropdown-menu-list-item--loading">
                Loading...
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

PeopleSearchDropdown.propTypes = {
  label: PropTypes.string,
  value: PropTypes.object,
  items: PropTypes.array,
  onSelect: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  totalItemsCount: PropTypes.number.isRequired,
  customStyles: PropTypes.object,
  disabled: PropTypes.bool,
};

export default PeopleSearchDropdown;
