import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Accordion from "../Accordion";
import QuestionRenderer from "./QuestionRenderer";
import { findQuestionById, getDescendantIds } from "./Helpers/helpers";
import "./SectionContent.scss";
import Loader from "../../../common/components/Loader";
import toast from "react-hot-toast";

const SectionContent = ({
  sections,
  selectedSection,
  questions,
  handleResponseSubmission,
  onSectionSelect,
  isLinear,
  loading,
}) => {
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const sectionRefs = useRef({});
  const containerRef = useRef(null);

  useEffect(() => {
    if (loading && containerRef.current) {
      containerRef.current.scrollTop = 0;
    }
  }, [loading]);

  // When loading finishes and a section is selected, scroll that section into view.
  useEffect(() => {
    if (!loading && selectedSection && sectionRefs.current[selectedSection.id]) {
      sectionRefs.current[selectedSection.id].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [loading, selectedSection]);

  useEffect(() => {
    const collectAnswers = (questionsList) => {
      let answers = {};
      questionsList.forEach((q) => {
        if (q.answer && q.answer.answer !== undefined) {
          answers[q.id] = q.answer.answer;
        }
        if (q.children) {
          if (Array.isArray(q.children)) {
            Object.assign(answers, collectAnswers(q.children));
          } else if (typeof q.children === "object") {
            Object.values(q.children).forEach((childItem) => {
              if (Array.isArray(childItem)) {
                Object.assign(answers, collectAnswers(childItem));
              } else if (typeof childItem === "object") {
                Object.values(childItem).forEach((childList) => {
                  if (Array.isArray(childList)) {
                    Object.assign(answers, collectAnswers(childList));
                  }
                });
              }
            });
          }
        }
      });
      return answers;
    };

    if (questions && questions.length > 0) {
      const initialAnswers = collectAnswers(questions);
      setSelectedAnswers(initialAnswers);
    } else {
      setSelectedAnswers({});
    }
  }, [questions]);

  const handleInputChange = (questionId, selectedValue) => {
    setSelectedAnswers((prevState) => {
      const newAnswers = { ...prevState, [questionId]: selectedValue };
      const questionObj = findQuestionById(questionId, questions);
      if (questionObj) {
        const descendantIds = getDescendantIds(questionObj);
        descendantIds.forEach((id) => {
          delete newAnswers[id];
        });
      }
      return newAnswers;
    });
  };

  // Recursive function to validate required fields
  const validateQuestions = (questionsList, answers) => {
    let missingRequired = false;

    questionsList.forEach((q) => {
      const answer = answers[q.id];
      if (q.required && (answer === null || answer === undefined || answer === "")) {
        missingRequired = true;
        // missingRequired.push(q.question_text); // Commented out as per request
      }

      // Process any children questions if available
      if (q.children && typeof q.children === "object") {
        let childQuestions = [];
        if (Array.isArray(q.children.always_visible)) {
          childQuestions = childQuestions.concat(q.children.always_visible);
        }
        if (
          q.children.option_dependent &&
          typeof q.children.option_dependent === "object"
        ) {
          Object.keys(q.children.option_dependent).forEach((key) => {
            if (Array.isArray(q.children.option_dependent[key])) {
              childQuestions = childQuestions.concat(q.children.option_dependent[key]);
            }
          });
        }
        if (childQuestions.length > 0) {
          missingRequired = missingRequired || validateQuestions(childQuestions, answers);
        }
      }
    });

    return missingRequired;
  };

  const currentPosition = selectedSection ? selectedSection.position : null;
  const nextSection = currentPosition
    ? sections.filter((section) => section.position > currentPosition).sort((a, b) => a.position - b.position)[0]
    : null;
  const isLastSection = !nextSection;

  // Handle submission with required field validation
  const handleSubmit = () => {
    const missingFields = validateQuestions(questions, selectedAnswers);
    if (missingFields) {
      toast.error("Please fill in all required fields:");
      return;
    }
    handleResponseSubmission(selectedAnswers);
  };

  return (
    <div
      className="section-content"
      ref={containerRef}
      style={{ position: "relative", overflowY: loading ? "hidden" : "auto" }}
    >
      {loading && (
        <div
          className="section-content-loader-overlay"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(255, 255, 255)",
          }}
        >
          <Loader customStyle={{ background: "transparent" }} />
        </div>
      )}
      {sections.map((section) => {
        const isOpen = selectedSection && selectedSection.id === section.id;
        return (
          <div key={section.id} ref={(el) => (sectionRefs.current[section.id] = el)}>
            <Accordion
              title={section.name}
              isOpen={isOpen}
              onHeaderClick={() => {
                if (!isOpen) {
                  onSectionSelect(section);
                }
              }}
              content={
                isOpen && (
                  <div className="section-content-body">
                    <div className="section-content-body-inner">
                      {questions && questions.length > 0 ? (
                        questions.map((question) => (
                          <QuestionRenderer
                            key={question.id}
                            question={question}
                            selectedAnswers={selectedAnswers}
                            handleInputChange={handleInputChange}
                          />
                        ))
                      ) : (
                        <p>No questions available.</p>
                      )}
                    </div>
                    <div className="section-content-body-outer">
                      <button className="primary-button" onClick={handleSubmit}>
                        {isLastSection ? "Submit" : "Next"}
                      </button>
                    </div>
                  </div>
                )
              }
            />
          </div>
        );
      })}
    </div>
  );
};

SectionContent.propTypes = {
  sections: PropTypes.array.isRequired,
  selectedSection: PropTypes.object,
  questions: PropTypes.array.isRequired,
  handleResponseSubmission: PropTypes.func.isRequired,
  onSectionSelect: PropTypes.func.isRequired,
  isLinear: PropTypes.bool,
  loading: PropTypes.bool,
};

export default SectionContent;
