import React from "react";
import PropTypes from "prop-types";
import "./DateInput.scss"; 

const DateInput = ({
  label,
  name,
  value,
  onChange,
  style = {},
  placeholder = "",
  disabled = false,
}) => {
  return (
    <div className={`date-input ${disabled ? "disabled" : ""}`}>
      <label htmlFor={name}>{label}:</label>
      <input
        id={name}
        type="date"
        name={name}
        value={value}
        onChange={onChange}
        style={style}
        placeholder={placeholder}
        disabled={disabled}
      />
    </div>
  );
};

DateInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  style: PropTypes.object,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
};

export default DateInput;
