import React, { useState, useEffect } from "react";
import { FaUserCircle, FaRegSmileBeam } from "react-icons/fa";
import toast from "react-hot-toast";
import "./Comments.scss";

const dummyComments = [
  {
    id: 1,
    avatar: <FaUserCircle size={35} style={{ color: "#FB770388" }} />,
    name: "John Doe",
    short_name: "JD",
    text: "This is a sample comment!",
    time: "2 hours ago",
  },
  {
    id: 2,
    avatar: <FaUserCircle size={35} style={{ color: "#FF5793" }} />,
    name: "Jane Smith",
    short_name: "JS",
    text: "Another comment on this post.",
    time: "1 hour ago",
  },
  {
    id: 3,
    avatar: <FaUserCircle size={35} style={{ color: "#FF5738" }} />,
    name: "Alex Johnson",
    short_name: "AJ",
    text: "Nice discussion happening here!",
    time: "30 minutes ago",
  },
  {
    id: 4,
    avatar: null,
    name: "Chris Lee",
    short_name: "CL",
    text: "I totally agree with the points made here. Great insights!",
    time: "15 minutes ago",
  },
  {
    id: 5,
    avatar: null,
    name: "Patricia Brown",
    short_name: "PB",
    text: "Interesting perspective. I hadn't thought about it that way before.",
    time: "10 minutes ago",
  },
  {
    id: 6,
    avatar: <FaUserCircle size={35} style={{ color: "#FF5733" }} />,
    name: "Michael Green",
    short_name: "MG",
    text: "Thanks for sharing this information. It's very helpful.",
    time: "5 minutes ago",
  },
  {
    id: 7,
    avatar: null,
    name: "Linda White",
    short_name: "LW",
    text: "Can someone explain this part in more detail? I'm a bit confused.",
    time: "Just now",
  },
];

// Dummy function to simulate fetching comments from an API.
const dummyFetchComments = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(dummyComments);
    }, 500);
  });
};

// Dummy function to simulate posting a comment.
const dummyPostComment = (newComment) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(newComment);
    }, 500); 
  });
};

const Comments = () => {
  const [commentText, setCommentText] = useState("");
  const [comments, setComments] = useState([]);
  const [loadingComments, setLoadingComments] = useState(false);
  const [postingComment, setPostingComment] = useState(false);

  useEffect(() => {
    setLoadingComments(true);
    dummyFetchComments()
      .then((fetchedComments) => {
        setComments(fetchedComments);
        toast.success("Comments fetched successfully");
      })
      .catch((err) => {
        toast.error("Error fetching comments: " + err.message);
      })
      .finally(() => {
        setLoadingComments(false);
      });
  }, []);

  const getRandomColor = () => {
    const colors = ["#FF5733", "#33FF57", "#3357FF", "#FF33A1", "#A133FF"];
    return colors[Math.floor(Math.random() * colors.length)];
  };

  const handlePostComment = () => {
    if (commentText.trim() !== "") {
      setPostingComment(true);
      const newComment = {
        id: comments.length + 1,
        avatar: <FaUserCircle size={30} />,
        name: "You",
        short_name: "Y",
        text: commentText,
        time: "Just now",
      };
      
      dummyPostComment(newComment)
        .then((postedComment) => {
          setComments([postedComment, ...comments]);
          toast.success("Comment posted successfully");
          setCommentText("");
        })
        .catch((err) => {
          toast.error("Error posting comment: " + err.message);
        })
        .finally(() => {
          setPostingComment(false);
        });
    }
  };

  return (
    <div className="comments">
      <div className="comments-input">
        <div className="comments-input-user">
          <div className="comments-input-user-avatar">
            <FaUserCircle size={40} className="comments-input-avatar" />
          </div>
          <div className="comments-input-user-name">Ellen Patrik</div>
        </div>

        <div className="comments-input-textbox">
          <textarea
            placeholder="Add a comment here..."
            value={commentText}
            onChange={(e) => setCommentText(e.target.value)}
          />
        </div>
        <div className="comments-input-button" onClick={handlePostComment}>
          <button className="primary-button" disabled={postingComment}>
            {postingComment ? "Posting..." : "Post"}
          </button>
        </div>
      </div>

      {/* Comments List */}
      <div className="comments-list">
        {loadingComments ? (
          <p>Loading comments...</p>
        ) : (
          comments.map((comment) => (
            <div key={comment.id} className="comments-list-item">
              <div className="comments-list-item-user">
                <div
                  className="comments-list-item-user-avatar"
                  style={{
                    backgroundColor: comment.avatar ? "transparent" : getRandomColor(),
                  }}
                >
                  {comment.avatar ? comment.avatar : comment.short_name}
                </div>
                <div className="comments-list-item-user-name">{comment.name}</div>
                <div className="comments-list-item-user-time">{comment.time}</div>
              </div>
              <div className="comments-list-item-comment_text">{comment.text}</div>
              <div className="comments-list-item-bottom">
                <FaRegSmileBeam size={20} className="comments-list-item-bottom-icon" />
                <span className="comments-list-item-bottom-text">Reply</span>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default Comments;
