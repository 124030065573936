import React from "react";
import { Route, Routes } from "react-router-dom";
import UserIncident from "./user/views/UserIncident";
import IncidentReportForm from "./user/views/IncidentReportForm";
import UserIncidentDetails from "./user/views/UserIncidentDetails";
const AppUserPolicyRouter = () => {
  return (
    <Routes>
      <Route path="" element={<UserIncident/>} />
      <Route path="/incident-report-form" element={<IncidentReportForm/>} />
      <Route path="/incident-details" element={<UserIncidentDetails/>} />
    </Routes>
  );
};

export default AppUserPolicyRouter;
