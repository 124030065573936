import React from "react";
import PropTypes from "prop-types";
import "./CheckboxInput.scss";

const CheckboxInput = ({
  questionId,
  label,
  selectedAnswer,
  onCheckboxToggle,
  isReadOnly,
  customStyles = {}
}) => {
    const prefixText = "Check if applicable:";
  return (
    <div className={`checkbox-input-question ${isReadOnly ? "disabled" : ""}`} style={customStyles.container}>
      <div className="checkbox-input-question_text" style={customStyles.label}>
        {prefixText}
      </div>
      <div className="checkbox-input-question_checkbox" style={customStyles.checkbox}>
        <input
          id={questionId}
          type="checkbox"
          className="form__checkbox-input"
          checked={!!selectedAnswer}
          onChange={() => {
            if (!isReadOnly) {
              onCheckboxToggle(!selectedAnswer);
            }
          }}
          disabled={isReadOnly}
        />
        <label htmlFor={questionId} className="form__checkbox-label">
        <span className="form__checkbox-box"></span>
          <span className="form__checkbox-text">
             {label}
          </span>
        </label>
      </div>
    </div>
  );
};

CheckboxInput.propTypes = {
  questionId: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  selectedAnswer: PropTypes.bool,
  onCheckboxToggle: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool,
  customStyles: PropTypes.object,
};

export default CheckboxInput;
