import React, { useState, useEffect } from "react";
import { getIncidentComments, createIncidentComment } from "../../services/api.services";
import { FaUserCircle, FaRegSmileBeam } from "react-icons/fa";
import toast from "react-hot-toast";
import "./Comments.scss";


const Comments = ({ header, incidentId }) => {
  const [commentText, setCommentText] = useState("");
  const [comments, setComments] = useState([]);
  const [loadingComments, setLoadingComments] = useState(false);
  const [postingComment, setPostingComment] = useState(false);


  const getRandomColor = () => {
    const colors = ["#FF5733", "#33FF57", "#3357FF", "#FF33A1", "#A133FF"];
    return colors[Math.floor(Math.random() * colors.length)];
  };

  const mapComment = (comment) => ({
    id: comment.id,
    avatar: comment.author && comment.author.avatar ? (
      <img
        src={comment.author.avatar}
        alt="avatar"
        className="comment-avatar-img"
      />
    ) : null,
    name: comment.author ? comment.author.full_name : "Unknown",
    short_name: comment.author ? comment.author.short_name : "NA",
    text: comment.comment_text,
    time: comment.created_at ? new Date(comment.created_at).toLocaleString() : "Just now",
    avatarColor: comment.author && comment.author.avatar ? "transparent" : getRandomColor(),
    replies: comment.replies ? comment.replies.map(mapComment) : [],
    parent: comment.parent || null,
  });

  const fetchComments = async (incidentId) => {
    setLoadingComments(true);
    try {
      const response = await getIncidentComments(header, incidentId);
      if (response.status === 200) {
        const fetchedComments = response.data.results;
        setComments(fetchedComments.map(mapComment));
        toast.success("Comments fetched successfully");
      } else {
        toast.error("Failed to fetch comments: Invalid response format.");
      }
    } catch (error) {
      toast.error("Error fetching comments: " + error.message);
    } finally {
      setLoadingComments(false);
    }
  };

  useEffect(() => {
    if (incidentId) {
      fetchComments(incidentId);
    }
  }, [incidentId]);

  const handlePostComment = async () => {
    if (commentText.trim() !== "" && incidentId) {
      setPostingComment(true);
      try {
        const payload = JSON.stringify({
          incident_id: incidentId,
          text: commentText,
          parent_id: null,
        });
        const response = await createIncidentComment(header, payload);
        if (response.status === 201 && response.data && response.data.isSuccess) {
          const newComment = mapComment(response.data.dataInfo);
          setComments([newComment, ...comments]);
          toast.success("Comment posted successfully");
          setCommentText("");
        } else {
          toast.error("Failed to post comment: Invalid response format.");
        }
      } catch (error) {
        toast.error("Error posting comment: " + error.message);
      } finally {
        setPostingComment(false);
      }
    }
  };

  return (
    <div className="comments">
      <div className="comments-input">
        <div className="comments-input-user">
          <div className="comments-input-user-avatar">
            <FaUserCircle size={40} className="comments-input-avatar" />
          </div>
          <div className="comments-input-user-name">Ellen Patrik</div>
        </div>
        <div className="comments-input-textbox">
          <textarea
            placeholder="Add a comment here..."
            value={commentText}
            onChange={(e) => setCommentText(e.target.value)}
          />
        </div>
        <div className="comments-input-button">
          <button
            className="primary-button"
            onClick={handlePostComment}
            disabled={postingComment}
          >
            {postingComment ? "Posting..." : "Post"}
          </button>
        </div>
      </div>

      <div className="comments-list">
        {loadingComments ? (
          <p>Loading comments...</p>
        ) : (
          comments.map((comment) => (
            <CommentItem
              key={comment.id}
              comment={comment}
              header={header}
              incidentId={incidentId}
            />
          ))
        )}
      </div>
    </div>
  );
};

export default Comments;

const CommentItem = ({ comment, header, incidentId }) => {
  const [replies, setReplies] = useState(comment.replies || []);
  const [repliesVisible, setRepliesVisible] = useState(false);
  const [repliesExpanded, setRepliesExpanded] = useState(false);
  const [replyFormVisible, setReplyFormVisible] = useState(false);
  const [replyText, setReplyText] = useState("");
  const [postingReply, setPostingReply] = useState(false);

  const toggleReplies = () => {
    if (!repliesVisible) {
      setRepliesVisible(true);
      setRepliesExpanded(false);
    } else {
      if (replies.length > 3 && !repliesExpanded) {
        setRepliesExpanded(true);
      } else {
        setRepliesVisible(false);
        setRepliesExpanded(false);
      }
    }
  };

  const displayedReplies = repliesVisible
    ? replies.length > 3 && !repliesExpanded
      ? replies.slice(0, 3)
      : replies
    : [];

  let toggleButtonText = "";
  if (!repliesVisible) {
    toggleButtonText = `View Replies (${replies.length})`;
  } else {
    if (replies.length > 3 && !repliesExpanded) {
      toggleButtonText = "View More";
    } else {
      toggleButtonText = "Hide Replies";
    }
  }

  const mapReply = (reply) => ({
    id: reply.id,
    avatar: reply.author && reply.author.avatar ? (
      <img
        src={reply.author.avatar}
        alt="avatar"
        className="comment-avatar-img"
      />
    ) : null,
    name: reply.author ? reply.author.full_name : "Unknown",
    short_name: reply.author ? reply.author.short_name : "NA",
    text: reply.comment_text,
    time: reply.created_at ? new Date(reply.created_at).toLocaleString() : "Just now",
    avatarColor:
      reply.author && reply.author.avatar ? "transparent" : getRandomColorStatic(),
    parent: reply.parent || null,
  });

  const getRandomColorStatic = () => {
    const colors = ["#FF5733", "#33FF57", "#3357FF", "#FF33A1", "#A133FF"];
    return colors[Math.floor(Math.random() * colors.length)];
  };

  const handlePostReply = async () => {
    if (replyText.trim() !== "") {
      setPostingReply(true);
      try {
        const payload = JSON.stringify({
          incident_id: incidentId,
          text: replyText,
          parent_id: comment.id,
        });
        const response = await createIncidentComment(header, payload);
        if (response.status === 201 && response.data && response.data.isSuccess) {
          const newReply = mapReply(response.data.dataInfo);
          setReplies((prevReplies) => [newReply, ...prevReplies]);
          setRepliesVisible(true);
          setRepliesExpanded(true);
          toast.success("Reply posted successfully");
          setReplyText("");
          setReplyFormVisible(false);
        } else {
          toast.error("Failed to post reply: Invalid response format.");
        }
      } catch (error) {
        toast.error("Error posting reply: " + error.message);
      } finally {
        setPostingReply(false);
      }
    }
  };

  return (
    <div className="comments-list-item">
      <div className="comments-list-item-user">
        <div
          className="comments-list-item-user-avatar"
          style={{
            "--avatar-color": comment.avatar ? "transparent" : comment.avatarColor,
          }}
        >
          {comment.avatar ? comment.avatar : comment.short_name}
        </div>
        <div className="comments-list-item-user-name">{comment.name}</div>
        <div className="comments-list-item-user-time">{comment.time}</div>
      </div>
      <div className="comments-list-item-comment_text">{comment.text}</div>
      <div className="comments-list-item-bottom">
        {!comment.parent && (
          <span
            className="comments-list-item-bottom-text reply-btn"
            onClick={() => setReplyFormVisible(!replyFormVisible)}
          >
            Reply
          </span>
        )}
        {replies.length > 0 && (
          <span
            className="comments-list-item-bottom-text toggle-replies-btn"
            onClick={toggleReplies}
          >
            {toggleButtonText}
          </span>
        )}
      </div>
      {!comment.parent && replyFormVisible && (
        <div className="comments-reply-form">
          <textarea
            placeholder="Write a reply..."
            value={replyText}
            onChange={(e) => setReplyText(e.target.value)}
          />
          <button
            onClick={handlePostReply}
            disabled={postingReply}
            className="primary-button"
          >
            {postingReply ? "Posting Reply..." : "Post Reply"}
          </button>
        </div>
      )}
      {repliesVisible && replies.length > 0 && (
        <div className="comments-replies-container">
          {displayedReplies.map((reply) => (
            <div key={reply.id} className="comments-reply-item">
              <div className="comments-list-item-user">
                <div
                  className="comments-list-item-user-avatar"
                  style={{
                    "--avatar-color": reply.avatar ? "transparent" : reply.avatarColor,
                  }}
                >
                  {reply.avatar ? reply.avatar : reply.short_name}
                </div>
                <div className="comments-list-item-user-name">{reply.name}</div>
                <div className="comments-list-item-user-time">{reply.time}</div>
              </div>
              <div className="comments-list-item-comment_text">{reply.text}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
