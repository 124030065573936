import React, { useState, useRef, useEffect } from "react";
import { FaChevronDown } from "react-icons/fa";
import PropTypes from "prop-types";
import useOptions from "../Helpers/useOptions";
import "./DropdownInput.scss";

const DropdownInput = ({
  label,
  options,
  selectedOption,
  onOptionSelect,
  customStyles = {},
  disabled,
  parameter = null,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);
  const { optionsData, loading } = useOptions(options, parameter);

  const handleMenuToggle = () => {
    if (!disabled) {
      setIsOpen((prev) => !prev);
    }
  };

  const handleOptionSelect = (option) => {
    if (!disabled) {
      onOptionSelect(option.id);
      setIsOpen(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (loading) {
    return <p>Loading options...</p>;
  }

  return (
    <div
      className={`dropdown-input-container ${disabled ? "disabled" : ""}`}
      style={customStyles.container}
      ref={menuRef}
    >
      <label style={customStyles.label}>{label}:</label>
      <div className="dropdown-header" onClick={handleMenuToggle}>
        <span style={customStyles.selectedOption}>
          {optionsData.find((option) => option.id === selectedOption)?.value ||
            "Select an option"}
        </span>
        <FaChevronDown className={`dropdown-icon ${isOpen ? "rotate" : ""}`} />
      </div>
      {isOpen && (
        <div className={`dropdown-input-list open`} style={customStyles.list}>
          {optionsData.map((option, index) => (
            <div
              key={index}
              className="dropdown-input-item"
              style={customStyles.item}
              onClick={() => handleOptionSelect(option)}
            >
              {option.value}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

DropdownInput.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.oneOfType([
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      values: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
    }),
    PropTypes.array,
  ]).isRequired,
  selectedOption: PropTypes.string,
  onOptionSelect: PropTypes.func.isRequired,
  customStyles: PropTypes.object,
  parameter: PropTypes.string,
  disabled: PropTypes.bool,
};

export default DropdownInput;
