import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import "./Table.scss";
import Tooltip from "./Tooltip";
import info from "../images/info.png";
import Loader from "./Loader";

const Table = ({
  headers,
  rows,
  tooltip,
  renderTooltipContent,
  getRowNavigationPath,
  isRowClickable = false,
  loading = false,
}) => {
  const navigate = useNavigate();
  const containerRef = useRef(null); 
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const [tooltipContent, setTooltipContent] = useState(null);

  const handleRowHover = (event, row) => {
    if (!renderTooltipContent) return;
    const rect = event.currentTarget.getBoundingClientRect();
    setTooltipPosition({ top: rect.top, left: rect.left });
    setTooltipContent(renderTooltipContent(row));
    setTooltipVisible(true);
  };

  const handleRowLeave = () => setTooltipVisible(false);

  const handleRowClick = (row) => {
    if (isRowClickable && typeof getRowNavigationPath === "function") {
      const navInfo = getRowNavigationPath(row);
      if (navInfo && navInfo.pathname) {
        navigate(navInfo.pathname, { state: navInfo.state });
      }
    }
  };

  useEffect(() => {
    if (loading && containerRef.current) {
      containerRef.current.scrollTop = 0;
    }
  }, [loading]);

  return (
    <div
      ref={containerRef}
      className="responsive-table-container"
      style={{
        position: "relative",
        height: "400px",
        overflowY: loading ? "hidden" : "auto",
      }}
    >
      {loading && (
        <div
          className="table-loader-overlay"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(255,255,255,0.6)",
          }}
        >
          <Loader customStyle={{ background: "transparent" }} />
        </div>
      )}

      <table className="responsive-table">
        <thead>
          <tr>
            {headers.map((header, index) => (
              <th key={index} className={index === 0 ? "sticky" : ""}>
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, rowIndex) => (
            <tr
              key={rowIndex}
              className={isRowClickable ? "clickable-row" : ""}
              onClick={() => isRowClickable && handleRowClick(row)}
            >
              {headers.map((header, cellIndex) => (
                <td key={cellIndex} className={cellIndex === 0 ? "sticky" : ""}>
                  <span>{row[header]}</span>
                  {cellIndex === 1 && tooltip && renderTooltipContent && (
                    <span
                      className="responsive-table-info"
                      onMouseEnter={(e) => handleRowHover(e, row)}
                      onMouseLeave={handleRowLeave}
                    >
                      <img src={info} alt="info" />
                    </span>
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      {tooltipVisible && (
        <Tooltip position={tooltipPosition} content={tooltipContent} />
      )}
    </div>
  );
};

Table.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  tooltip: PropTypes.bool,
  renderTooltipContent: PropTypes.func,
  getRowNavigationPath: PropTypes.func,
  isRowClickable: PropTypes.bool,
  loading: PropTypes.bool,
};

export default Table;
