import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../App";
import toast from "react-hot-toast";
import "./AdminTrainees.scss";
import AddTraineePopup from "../components/AddTraineePopup";
import DeleteTraineePopup from "../components/DeleteTraineePopup";
import BlockTraineePopup from "../components/BlockTraineePopup";
import UnblockTraineePopup from "../components/UnblockTraineePopup";
import DynamicContainer from "../components/DynamicContainer";
import FileUploadPopup from "../components/FileUploadPopup";
import AdminCustomCheckbox from "../components/AdminCustomCheckbox";
import SearchBox from "../../user/components/SearchBox";
import DropdownButton from "../../../common/components/DropdownButton";
import {
  getTraineeData,
  getDynamicContainerData,
  getUploadTaskStatus,
} from "../services/api.services";
import AppPagination from "../components/AppPagination";

const AdminTrainees = () => {
  const { state, dispatch } = useContext(AuthContext);
  const [traineeData, setTraineeData] = useState([]);
  const [selectedTraineeIds, setSelectedTraineeIds] = useState([]);
  const [isAnyCheckboxClicked, setIsAnyCheckboxClicked] = useState(false);
  const [taskId, setTaskId] = useState(localStorage.getItem("taskId") || "");
  const [taskStatus, setTaskStatus] = useState("");
  const [fileData, setFileData] = useState({});
  const [containerData, setContainerData] = useState({});
  const [isFileUpload, setIsFileUpload] = useState(!!taskId || false);
  const [searchKey, setSearchKey] = useState("");
  const navigate = useNavigate();

  const [recordsPerPage, setRecordsPerPage] = useState(5);
  const [pageNo, setPageNo] = useState(1);
  const [dataCount, setDataCount] = useState(0);

  const rowLimits = [
    { label: "5 Rows", handleClick: () => setRecordsPerPage(5) },
    { label: "10 Rows", handleClick: () => setRecordsPerPage(10) },
    { label: "20 Rows", handleClick: () => setRecordsPerPage(20) },
  ];

  const customDropdownStyle = {
    boxShadow: "rgb(182 182 182) 0px 0px 1px 1px",
    color: "black",
    height: "40px",
    width: "110px",
    borderRadius: "10px",
    fontSize: "14px",
  };

  // Add these useState for the CustomCheckbox, response data
  const [checkedData, setCheckedData] = useState([]);
  // const [responseData, setResponseData] = useState([])
  useEffect(() => {
    getTraineeDetails();
    fetchDynamicContainerData();
    if (taskId) {
      fetchTaskStatus(taskId);
    }
  }, [isFileUpload, pageNo, recordsPerPage, searchKey]);

  let header = {
    Authorization: "Token " + state.token,
  };

  const getTraineeDetails = async () => {
    try {
      const response = await getTraineeData(
        header,
        pageNo,
        recordsPerPage,
        searchKey
      );
      if (response.status == 200) {
        setDataCount(response.data.count);
        setTraineeData(response.data.results);
      } else {
        toast.error("Failed to fetch trainee data");
      }
    } catch (error) {
      toast.error("Error fetching trainee data: " + error.message);
    }
  };

  const fetchTaskStatus = async (taskId) => {
    try {
      const response = await getUploadTaskStatus(header, taskId);
      if (response.isSuccess) {
        const status = response.dataInfo.status;
        setTaskStatus(status);
        setFileData({
          fileName: response.dataInfo.filename,
          date: new Date(response.dataInfo.created_on).toLocaleDateString(),
          ...(status === "error" && { fileUrl: response.dataInfo.file }),
        });
      } else {
        setTaskStatus("");
      }
    } catch (error) {
      setTaskStatus("");
    }
  };

  const fetchDynamicContainerData = async () => {
    try {
      const response = await getDynamicContainerData(header);
      if (response.isSuccess) {
        setContainerData(response.dataInfo);
      } else {
        setContainerData(null);
      }
    } catch (error) {
      setContainerData(null);
    }
  };

  const handleCheckboxClick = (traineeId) => {
    setIsAnyCheckboxClicked(true);
    const index = selectedTraineeIds.indexOf(traineeId);
    if (index === -1) {
      setSelectedTraineeIds([...selectedTraineeIds, traineeId]);
    } else {
      setSelectedTraineeIds(
        selectedTraineeIds.filter((id) => id !== traineeId)
      );
    }
  };

  const handleAllCheckboxClick = () => {
    setIsAnyCheckboxClicked(true);
    if (selectedTraineeIds.length === traineeData.length) {
      setSelectedTraineeIds([]);
    } else {
      setSelectedTraineeIds(traineeData.map((trainee) => trainee.id));
    }
  };

  const refreshTraineeData = () => {
    setSelectedTraineeIds([]);
    // handleAllCheckboxClick();
    getTraineeDetails();
  };

  const handleEnrollCourses = () => {
    if (selectedTraineeIds.length !== 0) {
      const inactiveUsers = selectedTraineeIds.filter((id) => {
        const trainee = traineeData.find((trainee) => trainee.id === id);
        return trainee && trainee.status !== "Active";
      });

      if (inactiveUsers.length > 0) {
        toast.error("You can only enroll courses for active users.");
      } else {
        navigate("/learning/admin/enroll-courses", {
          state: selectedTraineeIds,
        });
      }
    }
  };

  const handleClose = () => {
    setTaskId(null);
    setIsFileUpload(false);
    localStorage.removeItem("taskId");
  };
  const handleTraineeDetails = (trainee) => {
    navigate("details", { state: trainee });
  };

  const handleSearch = (searchText) => {
    setSearchKey(searchText);
    return [];
  };
  const handleClearSearch = () => {
    setSearchKey("");
    // setKey(!key);
  };
  const handleValueClick = (value) => {
    setSearchKey(value);
  };

  return (
    <div className="admin-trainees">
      <div className="admin-trainees-title">
        <div className="admin-trainees-title-text">Trainees</div>
        <div className="admin-trainees-title-buttons">
          <div className="admin-trainees-title-buttons-search_box">
            <SearchBox
              searchData={[]}
              onSearch={handleSearch}
              onValueClick={handleValueClick}
              onClearSearch={handleClearSearch}
              useDynamicSearch={true}
            />
          </div>
          <button
            className={`admin-trainees-title-buttons-button ${
              selectedTraineeIds.length === 0 ? "" : "enabled"
            }`}
            onClick={handleEnrollCourses}
          >
            Enroll Courses
          </button>
          {/* <FileUploadPopup header={header} setIsFileUpload={setIsFileUpload} setTaskId={setTaskId}/> */}
          {/* <AddTraineePopup header={header} onSuccess={refreshTraineeData} /> */}
        </div>
      </div>
      {/* <DynamicContainer
        isFileUpload={isFileUpload}
        taskStatus={taskStatus}
        fileData={fileData}
        containerData={containerData}
        onClose={handleClose}
      /> */}
      <div className="admin-trainees-body">
        <div className="admin-trainees-body-table">
          <div className="admin-trainees-body-table-top">
            <div className="admin-trainees-body-table-top-selected">
              {selectedTraineeIds.length > 0 &&
                `${selectedTraineeIds.length} Selected`}
            </div>
            <div
              className={`admin-trainees-body-table-top-actions ${
                selectedTraineeIds.length === 0 ? "disabled" : ""
              }`}
            >
              <UnblockTraineePopup
                idArray={selectedTraineeIds}
                header={header}
                onSuccess={refreshTraineeData}
                isActive={selectedTraineeIds.length > 0}
              />
              <BlockTraineePopup
                idArray={selectedTraineeIds}
                header={header}
                onSuccess={refreshTraineeData}
                isActive={selectedTraineeIds.length > 0}
              />
              <DeleteTraineePopup
                idArray={selectedTraineeIds}
                header={header}
                onSuccess={refreshTraineeData}
                isActive={selectedTraineeIds.length > 0}
              />
            </div>
          </div>
          <div className="admin-trainees-body-table-head">
            <AdminCustomCheckbox
              selectAll={true}
              setCheckedData={setSelectedTraineeIds}
              checkedData={selectedTraineeIds}
              responseData={traineeData}
            ></AdminCustomCheckbox>
            <div className="admin-trainees-body-table-head-value">
              <div className="admin-trainees-body-table-head-value-item">
                User
              </div>
              <div className="admin-trainees-body-table-head-value-item">
                Enrolled Courses
              </div>
              <div className="admin-trainees-body-table-head-value-item">
                Status
              </div>
            </div>
          </div>
          <div className="admin-trainees-body-table-body">
            {traineeData.map((data, index) => (
              <div className="admin-trainees-body-table-body-row" key={index}>
                <AdminCustomCheckbox
                  selectAll={false}
                  data_id={data.id}
                  setCheckedData={setSelectedTraineeIds}
                  checkedData={selectedTraineeIds}
                ></AdminCustomCheckbox>
                <div className="admin-trainees-body-table-body-row-value">
                  <div className="admin-trainees-body-table-body-row-value-item">
                    <div className="admin-trainees-body-table-body-row-value-item-icon">
                      {data.short_name}
                    </div>
                    <div className="admin-trainees-body-table-body-row-value-item-text">
                      <div className="admin-trainees-body-table-body-row-value-item-text-name">
                        <span onClick={() => handleTraineeDetails(data.id)}>
                          {data.full_name}
                        </span>
                      </div>
                      <div className="admin-trainees-body-table-body-row-value-item-text-email">
                        {data.email}
                      </div>
                    </div>
                  </div>
                  <div className="admin-trainees-body-table-body-row-value-item">
                    {data.courses_enrolled}
                  </div>
                  <div className="admin-trainees-body-table-body-row-value-item">
                    <div
                      className="status-text"
                      style={{
                        color:
                          data.status === "Active"
                            ? "rgb(55, 209, 174)"
                            : "rgb(253, 143, 38)",
                      }}
                    >
                      {data.status}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {traineeData.length > 0 && (
        <div className="admin-trainees-bottom">
          <div className="admin-trainees-bottom-pagination">
            <AppPagination
              setCurrentPage={setPageNo}
              currentPage={pageNo}
              recordsPerPage={recordsPerPage}
              data_count={dataCount}
            />
          </div>
          <div className="admin-trainees-bottom-dropdown">
            <div className="admin-trainees-bottom-dropdown-text">
              Rows to display
            </div>
            <div className="admin-trainees-bottom-dropdown-button">
              <DropdownButton
                text="5 Rows"
                items={rowLimits}
                customStyle={customDropdownStyle}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminTrainees;
