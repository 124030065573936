/**
 * Recursively search for a question by id in a list of questions.
 * This function now handles children that may be:
 *  - Direct arrays (old structure or if using a single array)
 *  - Objects with keys (e.g., "always_visible" or "option_dependent")
 *    where the value can either be an array or an object mapping to arrays.
 */
export const findQuestionById = (questionId, questions) => {
  for (const question of questions) {
    if (question.id === questionId) return question;
    if (question.children && typeof question.children === "object") {
      // Iterate over each key in the children object
      for (const key in question.children) {
        const childContainer = question.children[key];
        if (Array.isArray(childContainer)) {
          // If the child container is an array, search within it.
          const found = findQuestionById(questionId, childContainer);
          if (found) return found;
        } else if (childContainer && typeof childContainer === "object") {
          // If it's an object (e.g., option_dependent mapping), iterate its keys.
          for (const subKey in childContainer) {
            const childArray = childContainer[subKey];
            if (Array.isArray(childArray)) {
              const found = findQuestionById(questionId, childArray);
              if (found) return found;
            }
          }
        }
      }
    }
  }
  return null;
};

/**
 * Recursively collect all descendant question IDs for a given question.
 * This function now handles:
 *  - Nested children defined as arrays or as objects mapping to arrays.
 *  - Dependent questions listed in the `dependent_questions` array.
 */
export const getDescendantIds = (question) => {
  let ids = [];

  if (question.children && typeof question.children === "object") {
    for (const key in question.children) {
      const childContainer = question.children[key];
      if (Array.isArray(childContainer)) {
        // Process an array of child questions.
        childContainer.forEach((childQuestion) => {
          ids.push(childQuestion.id);
          ids = ids.concat(getDescendantIds(childQuestion));
        });
      } else if (childContainer && typeof childContainer === "object") {
        // Process nested objects (e.g., option_dependent).
        for (const subKey in childContainer) {
          const childArray = childContainer[subKey];
          if (Array.isArray(childArray)) {
            childArray.forEach((childQuestion) => {
              ids.push(childQuestion.id);
              ids = ids.concat(getDescendantIds(childQuestion));
            });
          }
        }
      }
    }
  }

  if (Array.isArray(question.dependent_questions) && question.dependent_questions.length > 0) {
    ids = ids.concat(question.dependent_questions);
  }
  return ids;
};
