import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASEURL,
  // headers: {
  //   "Content-Type": "multipart/form-data",
  // },
});

export const initiateIncident = async (header) => {
  try {
    const res = await instance.post(
      "api/incident-app/user/incident/",
      {},
      { headers: header }
    );
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const getIncidents = async (header, page = 1, pageSize = 5, searchKey = null) => {
  try {
    let url;
    if (searchKey) {
      url = `api/incident-app/user/incident/?search=${searchKey}`;
    } else {
      url = `api/incident-app/user/incident/?page=${page}&page_size=${pageSize}`;
    }
    const res = await instance.get(url, {
      headers: header,
    });
    return res;
  } catch (err) {
    return { status: "error", message: err.message };
  }
};

export const getIncidentDetails = async (header, incidentId) => {
  try {
    const res = await instance.get(
      `api/incident-app/user/incident/${incidentId}/`,
      { headers: header }
    );
    return res;
  } catch (err) {
    return { status: "error", message: err.message };
  }
};

export const updateIncidentStatus = async (header, incidentId, payload) => {
  try {
    const res = await instance.patch(
      `api/incident-app/user/incident/${incidentId}/`,
      JSON.parse(payload),
      { headers: { ...header, "Content-Type": "application/json" } }
    );
    return res;
  } catch (err) {
    return { status: "error", message: err.message };
  }
};

export const getFormSections = async (header, incidentId) => {
  try {
    const res = await instance.get(
      `api/incident-app/user/form-sections/?incident_id=${incidentId}`,
      {
        headers: header,
      }
    );
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const getFormSectionQuestions = async (header, sectionId) => {
  try {
    const res = await instance.get(
      `api/incident-app/user/form-sections/${sectionId}/`,
      { headers: header }
    );
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const submitSectionResponse = async (header, payload) => {
  try {
    const res = await instance.post(
      "api/incident-app/user/incident-form-answer/",
      JSON.parse(payload),
      { headers: { ...header, "Content-Type": "application/json" } }
    );
    return res;
  } catch (err) {
    return { status: "error", message: err.message };
  }
};

export const getIncidentUsers = async (
  header,
  page = 1,
  pageSize = 10,
  searchKey = null
) => {
  try {
    let url = `api/incident-app/user/incident/get-users/?page=${page}&page_size=${pageSize}`;

    if (searchKey && searchKey.trim() !== "") {
      url = `api/incident-app/user/incident/get-users/?search=${searchKey}&page_size=${pageSize}`;
    }

    const res = await instance.get(url, { headers: header });
    return res;
  } catch (err) {
    return { status: "error", message: err.message };
  }
};

export const getIncidentAdmins = async (
  header,
  page = 1,
  pageSize = 10,
  searchKey = null
) => {
  try {
    let url = `api/incident-app/user/incident/get-admins/?page=${page}&page_size=${pageSize}`;

    if (searchKey && searchKey.trim() !== "") {
      url = `api/incident-app/user/incident/get-admins/?search=${searchKey}&page_size=${pageSize}`;
    }

    const res = await instance.get(url, { headers: header });
    return res;
  } catch (err) {
    return { status: "error", message: err.message };
  }
};


export const assignIncident = async (header, payload) => {
  try {
    const res = await instance.post(
      "api/incident-app/user/incident/assign-incident/",
      JSON.parse(payload),
      { headers: { ...header, "Content-Type": "application/json" } }
    );
    return res;
  } catch (err) {
    return { status: "error", message: err.message };
  }
};

export const addStakeholder = async (header, payload) => {
  try {
    const res = await instance.post(
      "api/incident-app/user/incident/add-stakeholder/",
      JSON.parse(payload),
      { headers: { ...header, "Content-Type": "application/json" } }
    );
    return res;
  } catch (err) {
    return { status: "error", message: err.message };
  }
};

export const removeStakeholder = async (header, payload) => {
  try {
    const res = await instance.post(
      "api/incident-app/user/incident/remove-stakeholder/",
      JSON.parse(payload),
      { headers: { ...header, "Content-Type": "application/json" } }
    );
    return res;
  } catch (err) {
    return { status: "error", message: err.message };
  }
};

export const getIncidentComments = async (header, incidentId) => {
  try {
    const res = await instance.get(
      `api/incident-app/user/incident/get-comments/?incident_id=${incidentId}`,
      {
        headers: header,
      }
    );
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const createIncidentComment = async (header, payload) => {
  try {
    const res = await instance.post(
      "api/incident-app/user/incident/add-comment/",
      JSON.parse(payload),
      { headers: { ...header, "Content-Type": "application/json" } }
    );
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};