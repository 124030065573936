import React, { useState, useRef } from "react";
import { FaCloudUploadAlt, FaTrashAlt, FaRegFileAlt, FaUpload } from "react-icons/fa";
import toast from "react-hot-toast";
import "./FileInput.scss";

const FileInput = ({ label, onFileSelect }) => {
  const [uploadedFile, setUploadedFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleFileUpload = (files) => {
    if (files.length > 0) {
      const file = files[0];
      setUploadedFile(file);
      onFileSelect(file);
    }
  };

  const handleFileInputChange = (e) => {
    handleFileUpload(e.target.files);
  };

  const formatFileSize = (size) => {
    if (size < 1024) return `${size} B`;
    else if (size < 1024 * 1024) return `${(size / 1024).toFixed(2)} KB`;
    else return `${(size / (1024 * 1024)).toFixed(2)} MB`;
  };

  const handleCancelUpload = () => {
    setUploadedFile(null);
    fileInputRef.current.value = null;
  };

  return (
    <div className="file-input">
      <label>{label}</label>
      {!uploadedFile ? (
        <div className="file-input-body">
          <div className="file-input-icon"><FaCloudUploadAlt /></div>
          <div className="file-input-text">Drag files to upload</div>
          <div className="file-input-subtext">or</div>
          <div className="file-input-button" onClick={() => fileInputRef.current.click()}>
            Browse Files
          </div>
          <input type="file" ref={fileInputRef} style={{ display: "none" }} onChange={handleFileInputChange} />
        </div>
      ) : (
        <div className="file-input-uploaded">
          <div className="file-input-file">
            <FaRegFileAlt />
            <div>
              <div>{uploadedFile.name}</div>
              <div className="file-size">{formatFileSize(uploadedFile.size)}</div>
            </div>
          </div>
          <div className="file-input-actions">
            {/* <button className="upload-btn" onClick={() => toast.success("Uploading...")}>
              <FaUpload /> Upload
            </button> */}
            <button className="cancel-btn" onClick={handleCancelUpload}>
              <FaTrashAlt /> Remove
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default FileInput;
