import React, { useState, useEffect, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { FaExclamationTriangle } from "react-icons/fa";
import toast from "react-hot-toast";
import PopupModal from "../../../learning/admin/components/PopupModal";
import SearchBox from "../../../learning/user/components/SearchBox";
import Table from "../../../common/components/Table";
import TableFooter from "../../../common/components/TableFooter";
import AppLoader from "../../../learning/user/components/AppLoader";
import "../../../theme/_buttons.scss";
import "./UserIncident.scss";
import { AuthContext } from "../../../App";
import { getIncidents, initiateIncident } from "../services/api.services";

const UserIncident = () => {
  const navigate = useNavigate();
  const { state } = useContext(AuthContext);

  // Memoize the header so it doesn't change on every render
  const header = useMemo(
    () => ({
      Authorization: "Token " + state.token,
    }),
    [state.token]
  );

  const [key, setKey] = useState(false);
  const [requestNames, setRequestNames] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [incidentData, setIncidentData] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const [recordsPerPage, setRecordsPerPage] = useState(5);
  const [pageNo, setPageNo] = useState(1);

  const headers = [
    "#",
    "Incident ID",
    "Incident Name",
    "Date of the Event",
    "Reporting Manager",
    "Priority",
    "Status",
  ];

  const rowLimits = [
    { label: "5 Rows", handleClick: () => setRecordsPerPage(5) },
    { label: "10 Rows", handleClick: () => setRecordsPerPage(10) },
    { label: "20 Rows", handleClick: () => setRecordsPerPage(20) },
  ];

  // initiate incident and navigate to report form.
  const handleReportIncident = async () => {
    setLoading(true);
    const loadingToastId = toast.loading("Initiating incident...");
    try {
      const response = await initiateIncident(header);
      if (response.status === 201) {
        toast.dismiss(loadingToastId);
        toast.success("Incident initiated successfully");
        const { id, detail_form_is_linear } = response.data.dataInfo;
        navigate("/incident/user/incident-report-form", {
          state: { incidentFormId: id, isLinear: detail_form_is_linear },
        });
      } else {
        toast.dismiss(loadingToastId);
        toast.error("Failed to initiate incident.");
      }
    } catch (error) {
      toast.dismiss(loadingToastId);
      toast.error("Error initiating incident: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handleClearSearch = () => {
    setSearchKey("");
    setKey((prev) => !prev);
  };

  const handleRequestSearch = (searchText) => {
    setSearchKey(searchText);
    return requestNames;
  };

  const handleClearRequestSearch = () => {
    setSearchKey("");
    setKey((prev) => !prev);
  };

  const handleRequestValueClick = (requestName) => {
    setSearchKey(requestName);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await getIncidents(
          header,
          pageNo,
          recordsPerPage,
          searchKey
        );

        if (response.status === 200) {
          const data = response.data;
          setDataCount(data.count);

          const mappedRows = data.results.map((incident, index) => ({
            "#": index + 1 + (pageNo - 1) * recordsPerPage,
            Id: incident.id,
            "Incident ID": incident.incident_id,
            "Incident Name": incident.incident_type,
            "Date of the Event": incident.date_of_event
              ? formatDate(incident.date_of_event)
              : "N/A",
            "Reporting Manager": incident.reported_by,
            Priority: "N/A",
            Status: incident.status,
          }));

          setIncidentData(mappedRows);
        } else {
          toast.error("Failed to fetch incident data");
        }
      } catch (error) {
        console.error("Error fetching incidents:", error);
        toast.error("Failed to fetch incidents.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [header, pageNo, searchKey, recordsPerPage]);

  return (
    <div className="user-incident">
      <div className="user-incident-title">Incident Reports</div>
      <div className="user-incident-body">
        <div className="user-incident-body-tab-content">
          <div className="user-incident-body-tab-content-top">
            <button
              className="primary-button"
              style={{ width: "150px" }}
              onClick={handleReportIncident}
            >
              <FaExclamationTriangle />
              Report Incident
            </button>

            <div className="user-incident-body-tab-content-top-search_box">
              <SearchBox
                searchData={[]}
                onSearch={handleRequestSearch}
                onValueClick={handleRequestValueClick}
                onClearSearch={handleClearRequestSearch}
                useDynamicSearch={true}
              />
            </div>
          </div>

          <Table
            headers={headers}
            rows={incidentData}
            tooltip={false}
            getRowNavigationPath={(row) => {
              if (row.Status.toLowerCase() === "draft") {
                return {
                  pathname: "/incident/user/incident-report-form",
                  state: {
                    incidentFormId: row["Id"],
                    isLinear: true,
                  },
                };
              } else {
                return {
                  pathname: "/incident/user/incident-details",
                  state: { incidentId: row["Id"] },
                };
              }
            }}
            isRowClickable={true}
            loading={loading}
          />

          {dataCount > recordsPerPage && (
            <TableFooter
              setCurrentPage={setPageNo}
              currentPage={pageNo}
              recordsPerPage={recordsPerPage}
              dataCount={dataCount}
              rowLimits={rowLimits}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default UserIncident;
