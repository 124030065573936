import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import "./Tooltip.scss";

const Tooltip = ({ position, content }) => {
  const ref = useRef(null);
  const[halfWidth, setHalfWidth] = useState(0);
  const[tooltipArrowPosition, setTooltipArrowPosition] = useState(0);
  const[tooptipTopPosition, setTooltipTopPosition] = useState(0);
  useEffect(() => {
    setHalfWidth(ref.current.offsetWidth / 2);
    let windowHeight = window.innerHeight;
    let tooltipHeight = ref.current.offsetHeight; 
    let iconPositionTop = position.top;
    let tooltipHeightPosition = iconPositionTop + tooltipHeight;
    let tooltipPositionTop = 0;
    if(tooltipHeightPosition > windowHeight){
      tooltipPositionTop = windowHeight - tooltipHeight - 10;
      setTooltipTopPosition(tooltipPositionTop);
      let arrowPosition = iconPositionTop - tooltipPositionTop
      if(tooltipHeight - 10 <= arrowPosition){
        arrowPosition = arrowPosition - 25;
      }
      setTooltipArrowPosition(arrowPosition)
    }
    else{
      tooltipPositionTop = position.top - 10;
      setTooltipTopPosition(tooltipPositionTop - 10);
      let arrowPosition = iconPositionTop - tooltipPositionTop +10
      if(tooltipHeight - 10 <= arrowPosition){
        arrowPosition = arrowPosition - 25;
      }
      setTooltipArrowPosition(arrowPosition)
    }
  }, []);
  return (
    <div
      className="tooltip-box"
      style={{
        top: `${tooptipTopPosition}px`,
        left: `${position.left + halfWidth + 20}px`,
        '--tooltipArrowPosition': `${tooltipArrowPosition}px`,
        '--tooltipArrowShadowPosition': `${tooltipArrowPosition - 5}px`
      }}
    >
      <div ref={ref} className="tooltip-content">{content}</div>
    </div>
  );
};

Tooltip.propTypes = {
  position: PropTypes.shape({
    top: PropTypes.number.isRequired,
    left: PropTypes.number.isRequired,
  }).isRequired,
  content: PropTypes.node.isRequired,
};

export default Tooltip;