import React from "react";
import careLoader from "../images/CareLoader.gif";

const Loader = ({ customStyle = {} }) => {
    return (
        <div style={{ ...defaultStyle, ...customStyle }}>
            <img src={careLoader} alt="Loading..." style={imageStyle} />
        </div>
    );
};


const defaultStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%", 
    width: "100%", 
    position: "absolute", 
    top: 0,
    left: 0,
    background: "rgba(255, 255, 255, 0.6)", 
};

const imageStyle = {
    width: "80px", 
    height: "80px",
};

export default Loader;
