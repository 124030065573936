import { useEffect, useState, useContext } from "react";
import { fetchOptionsData } from "../../../services/api.services";
import { AuthContext } from "../../../../App";

const useOptions = (options, parameter = null) => {
  const [optionsData, setOptionsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { state } = useContext(AuthContext);
  const header = { Authorization: "Token " + state.token };

  useEffect(() => {
    const fetchAndSetOptions = async () => {
      if (!options) return;

      setLoading(true);
      try {
        switch (options.type) {
          case "list":
            setOptionsData(options.values.map((value) => ({ id: value, value })));
            break;
          case "api":
            const apiData = await fetchOptionsData(header, options.values);
            setOptionsData(apiData);
            break;
          case "api/parameter":
            if (parameter) {
              const apiParameterData = await fetchOptionsData(header, options.values, parameter);
              setOptionsData(apiParameterData);
            } else {
              setOptionsData([]); 
            }
            break;
          case "key/value":
            setOptionsData(options.values);
            break;
          default:
            setOptionsData(Array.isArray(options) ? options : []);
            break;
        }
      } catch (error) {
        console.error("Error processing options:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAndSetOptions();
  }, [options, parameter]); 

  return { optionsData, loading };
};

export default useOptions;