import React, { useContext, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../../App";
import UserIncidentInfo from "../components/UserIncidentInfo";
import UserIncidentActions from "../components/UserIncidentActions";
import { FaArrowLeft } from "react-icons/fa";
import "./UserIncidentDetails.scss";

const UserIncidentDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { state: authState } = useContext(AuthContext);
  const header = useMemo(
    () => ({
      Authorization: "Token " + authState.token,
    }),
    [authState.token]
  );

  const incidentId =
    location.state && location.state.incidentId
      ? location.state.incidentId
      : null;

  return (
    <div className="user-incident-details">
      <div className="user-incident-details-title">
        <div
          className="user-incident-details-title-icon"
          onClick={() => navigate("/incident/user/")}
        >
          <FaArrowLeft />
        </div>
        <div className="user-incident-details-title-text">
          Incident Reports
        </div>
      </div>
      {/* <div className="user-incident-details-title">Incident Reports</div> */}
      <div className="user-incident-details-body">
        <UserIncidentInfo header={header} incidentId={incidentId} />
        <UserIncidentActions header={header} incidentId={incidentId} />
      </div>
    </div>
  );
};

export default UserIncidentDetails;
