import React from "react";
import PropTypes from "prop-types";
import "./TextAreaInput.scss";

const TextAreaInput = ({
  label,
  name,
  value,
  onChange,
  style = {},
  placeholder = "",
  disabled = false,
}) => {
  return (
    <div className={`text-area-input ${disabled ? "disabled" : ""}`}>
      <label htmlFor={name}>{label}:</label>
      <textarea
        id={name}
        name={name}
        value={value}
        onChange={disabled ? undefined : onChange}
        style={style}
        placeholder={placeholder}
        disabled={disabled}
      />
    </div>
  );
};

TextAreaInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  style: PropTypes.object,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
};

export default TextAreaInput;
