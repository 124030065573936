import React, { useState, useEffect } from "react";
import { FaTrash } from "react-icons/fa";
import PeopleSearchDropdown from "../../../common/components/PeopleSearchDropdown";
import DropdownButton from "../../../common/components/DropdownButton";
import PeopleAddSearch from "../../../common/components/PeopleAddSearch";
import Loader from "../../../common/components/Loader";
import toast from "react-hot-toast";
import "./UserIncidentInfo.scss";
import {
  getIncidentDetails,
  assignIncident,
  getIncidentAdmins,
  getIncidentUsers,
  updateIncidentStatus,
  addStakeholder,
  removeStakeholder,
} from "../services/api.services";

const UserIncidentInfo = ({ header, incidentId }) => {
  const [incidentData, setIncidentData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedAssignee, setSelectedAssignee] = useState("");
  const [assigneeOptions, setAssigneeOptions] = useState([]);
  const [assigneeTotal, setAssigneeTotal] = useState(0);

  const [stakeholderOptions, setStakeholderOptions] = useState([]);
  const [stakeholderTotal, setStakeholderTotal] = useState(0);

  // Custom styles for each incident status.
  const statusStyles = {
    draft: {
      backgroundColor: "rgb(173 173 173)",
      color: "white",
      padding: "10px",
      borderRadius: "5px",
    },
    open: {
      backgroundColor: "#3C763D",
      color: "white",
      padding: "10px",
      borderRadius: "5px",
    },
    under_investigation: {
      backgroundColor: "rgb(255 161 0)",
      color: "white",
      padding: "10px",
      borderRadius: "5px",
    },
    closed: {
      backgroundColor: "#A94442",
      color: "white",
      padding: "10px",
      borderRadius: "5px",
    },
  };

  useEffect(() => {
    if (!incidentId) {
      toast.error("No incident ID provided");
      setLoading(false);
      return;
    }

    const fetchDetails = async () => {
      try {
        const response = await getIncidentDetails(header, incidentId);
        if (response.status === 200) {
          setIncidentData(response.data);
          setSelectedAssignee(response.data.assignee);
        } else {
          toast.error("Failed to fetch incident details");
        }
      } catch (error) {
        toast.error("Error fetching incident details: " + error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchDetails();
  }, [header, incidentId]);

  // Handler to update the incident status.
  const handleStatusChange = async (newStatus) => {
    const payload = JSON.stringify({ status: newStatus });
    const res = await updateIncidentStatus(header, incidentId, payload);
    if (res.status === 200) {
      setIncidentData((prev) => ({ ...prev, status: newStatus }));
      toast.success("Status updated successfully");
    } else {
      toast.error("Failed to update status: " + res.message);
    }
  };

  // status options for the dropdown.
  const statusOptions = [
    {
      label: "Draft",
      value: "draft",
      handleClick: () => handleStatusChange("draft"),
    },
    {
      label: "Reviewing",
      value: "open",
      handleClick: () => handleStatusChange("open"),
    },
    {
      label: "Under Investigation",
      value: "under_investigation",
      handleClick: () => handleStatusChange("under_investigation"),
    },
    {
      label: "Rejected",
      value: "closed",
      handleClick: () => handleStatusChange("closed"),
    },
  ];
  const formatDateTime = (dateString) => {
    if (!dateString) return "N/A";
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    };
    const date = new Date(dateString);
    return date.toLocaleString("en-US", options);
  };

  // Render the incident event content.
  const renderEventContent = () => {
    if (!incidentData) {
      return <p>No incident data available.</p>;
    }
    const {
      incident_id,
      incident_type,
      date_of_event,
      status,
      risk_score,
      location,
      created_on,
      description,
    } = incidentData;

    return (
      <>
        <div className="user-incident-info-event-top">
          <div className="user-incident-info-event-top-left">
            <div className="user-incident-info-event-top-left-title">
              <div className="user-incident-info-event-top-left-title-incident-number">
                {incident_id || "N/A"}
              </div>
              <div className="user-incident-info-event-top-left-title-incident-name">
                {incident_type || "N/A"}
              </div>
            </div>
            <div className="user-incident-info-event-top-left-status">
              <DropdownButton
                text={status || "N/A"}
                items={statusOptions}
                customStyle={{
                  padding: "10px",
                  borderRadius: "5px",
                  minWidth: "120px",
                  textAlign: "center",
                  ...(statusStyles[status] || {}),
                }}
              />
            </div>
          </div>
          <div className="user-incident-info-event-top-right">
            <div className="user-incident-info-event-top-right-risk_score">
              <div className="user-incident-info-event-top-right-risk_score-key">
                Risk Score
              </div>
              <div className="user-incident-info-event-top-right-risk_score-value">
                {risk_score || "N/A"}
              </div>
            </div>
            <div className="user-incident-info-event-top-right-action">
              <button className="secondary-button">Rescore Risk</button>
            </div>
          </div>
        </div>

        <div className="user-incident-info-event-mid">
          <DetailItem label="Location:" value={location || "N/A"} />
          <DetailItem
            label="Created Date:"
            value={formatDateTime(created_on) || "N/A"}
          />
          <DetailItem
            label="Date of the Event:"
            value={formatDateTime(date_of_event) || "N/A"}
          />
        </div>

        <div className="user-incident-info-event-bottom">
          <div className="user-incident-info-event-bottom-description">
            <div className="user-incident-info-event-bottom-description-key">
              Description:
            </div>
            <div className="user-incident-info-event-bottom-description-value">
              {description || "N/A"}
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="user-incident-info">
      <div className="user-incident-info-event">
        <div className="event-content">{renderEventContent()}</div>
        {loading && (
          <div className="incident-info-spinner">
            <Loader />
          </div>
        )}
      </div>

      <div className="user-incident-info-people">
        {incidentData && (
          <>
            <div className="user-incident-info-people-item">
              <div className="user-incident-info-people-item-key">
                Reported By:
              </div>
              <div className="user-incident-info-people-item-value">
                {incidentData.reported_by || "N/A"}
              </div>
            </div>
            <div className="user-incident-info-people-item">
              <PeopleSearchDropdown
                label="Assignee"
                value={selectedAssignee}
                items={assigneeOptions}
                totalItemsCount={assigneeTotal}
                customStyles={{
                  header: {
                    height: "45px",
                  },
                }}
                onSelect={async (person) => {
                  setSelectedAssignee(person);
                  const payload = JSON.stringify({
                    incident_id: incidentData.id,
                    assignee: person.id,
                  });
                  const res = await assignIncident(header, payload);
                  if (res.status === 200) {
                    console.log("Incident assigned successfully");
                  } else {
                    console.error("Error assigning incident:", res.message);
                  }
                }}
                onSearch={async (searchTerm, page) => {
                  const res = await getIncidentAdmins(
                    header,
                    page,
                    5,
                    searchTerm
                  );
                  if (res.status === 200) {
                    const newItems = res.data.results;
                    if (page === 1) {
                      setAssigneeOptions(newItems);
                    } else {
                      setAssigneeOptions((prev) => [...prev, ...newItems]);
                    }
                    setAssigneeTotal(res.data.count);
                  }
                }}
              />
            </div>
            <div className="user-incident-info-people-item">
              <div className="user-incident-info-people-item-key">
                Stakeholders:
              </div>
              {incidentData.stakeholders &&
              incidentData.stakeholders.length > 0 ? (
                <div className="stakeholder-list">
                  {incidentData.stakeholders.map((stakeholder, index) => (
                    <div
                      key={index}
                      className="user-incident-info-people-item-value stakeholder-action-item"
                    >
                      <span className="stakeholder-name">
                        {stakeholder.full_name}
                      </span>
                      <FaTrash
                        className="stakeholder-trash-icon"
                        onClick={async (e) => {
                          // Prevent triggering parent's click events.
                          e.stopPropagation();
                          const payload = JSON.stringify({
                            incident_id: incidentData.id,
                            stakeholder: stakeholder.id,
                          });
                          const res = await removeStakeholder(header, payload);
                          if (res.status === 200) {
                            toast.success("Stakeholder removed successfully");
                            setIncidentData((prev) => ({
                              ...prev,
                              stakeholders: prev.stakeholders.filter(
                                (s) => s.id !== stakeholder.id
                              ),
                            }));
                          } else {
                            toast.error(
                              "Error removing stakeholder: " + res.message
                            );
                          }
                        }}
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <div className="user-incident-info-people-item-value">N/A</div>
              )}
            </div>

            <div className="user-incident-info-people-item">
              <PeopleAddSearch
                label="Add Stakeholder"
                onSelect={async (person) => {
                  // Payload for addStakeholder API
                  const payload = JSON.stringify({
                    incident_id: incidentData.id,
                    stakeholder: person.id,
                  });
                  const res = await addStakeholder(header, payload);
                  if (res.status === 200) {
                    toast.success("Stakeholder added successfully");
                    // Update incidentData stakeholders list
                    setIncidentData((prev) => ({
                      ...prev,
                      stakeholders: prev.stakeholders
                        ? [...prev.stakeholders, person]
                        : [person],
                    }));
                  } else {
                    toast.error("Error adding stakeholder: " + res.message);
                  }
                }}
                onSearch={async (searchTerm, page) => {
                  const res = await getIncidentUsers(
                    header,
                    page,
                    5,
                    searchTerm
                  );
                  if (res.status === 200) {
                    const newItems = res.data.results;
                    if (page === 1) {
                      setStakeholderOptions(newItems);
                    } else {
                      setStakeholderOptions((prev) => [...prev, ...newItems]);
                    }
                    setStakeholderTotal(res.data.count);
                  }
                }}
                items={stakeholderOptions}
                totalItemsCount={stakeholderTotal}
                customStyles={{
                  header: {
                    height: "50px",
                  },
                }}
                placeholder="Search people..."
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const DetailItem = ({ label, value }) => (
  <div className="user-incident-info-event-mid-item">
    <div className="user-incident-info-event-mid-item-key">{label}</div>
    <div className="user-incident-info-event-mid-item-value">{value}</div>
  </div>
);

export default UserIncidentInfo;
