import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASEURL,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

export const createStaff = async (header, formData) => {
  try {
    const res = await instance.post("api/user-app/admin/user/", formData, {
      headers: {
        ...header,
        "Content-Type": "multipart/form-data",
      },
    });
    return res;
  } catch (err) {
    return { status: "error", message: err['response'].data.dataInfo };
  }
};


export const updateStaff = async (header, Id, formData) => {
  try {
    const res = await instance.patch(`api/user-app/admin/user/${Id}/`, formData, {
      headers: {
        ...header,
        "Content-Type": "multipart/form-data",
      },
    });
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const deleteStaff = async (header, requestIds) => {
  try {
    const res = await instance.post(
      "api/user-app/admin/user/bulk-delete/",
      { request_ids: requestIds },
      { headers: header }
    );
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};
export const getUserData = async (
  header,
  page = 1,
  pageSize = 10,
  manager = null,
  is_active = null,
  date = null,
  jobrole = null,
  searchKey = null
) => {
  try {
    let url = `api/user-app/admin/user/?page=${page}&page_size=${pageSize}`;

    // Conditionally add filters to the URL
    if (manager !== null) {
      url += `&manager__id=${manager}`;
    }

    if (is_active !== null) {
      url += `&is_active=${is_active}`;
    }

    if (date !== null) {
      url += `&date=${date}`;
    }

    if (jobrole !== null) {
      url += `&jobrole__name=${jobrole}`;
    }

    // Only include searchKey if it is provided
    if (searchKey && searchKey.trim() !== "") {
      url = `api/user-app/admin/user/?search=${searchKey}&page_size=${pageSize}`;
    }

    const res = await instance.get(url, {
      headers: header,
    });
    return res;
  } catch (err) {
    return { status: "error", message: err.message };
  }
};

export const getJobroles = async (header) => {
  try {
    let url = "api/user-app/admin/jobrole/";
    const res = await instance.get(url, {
      headers: header,
    });
    return res;
  } catch (err) {
    return { status: "error", message: err.message };
  }
};

export const getManagers = async (header, page = 1, pageSize = 10) => {
  try {
    let url = "/api/user-app/admin/user/managers/";
    const res = await instance.get(url, {
      headers: header,
    });
    return res;
  } catch (err) {
    return { status: "error", message: err.message };
  }
};


export const downloadExcelTemplate = async (header) => {
  try {
    const res = await instance.get("api/user-app/admin/user/get-user-template/", {
      headers: header,
      responseType: "blob",
    });
    return res;
  } catch (err) {
    console.log("Error downloading template:", err);
    return { status: "error", message: err };
  }
};

export const uploadExcelTemplate = async (file, header) => {
  const formData = new FormData();
  formData.append("excel", file);

  try {
    const res = await instance.post(
      "api/user-app/admin/user/upload-user/",
      formData,
      { headers: header }
    );
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const getDynamicContainerData = async (header) => {
  try {
    const res = await instance.get(
      "api/user-app/admin/user/get-dynamic-dashboard-data/",
      { headers: header }
    );
    return res.data;
  } catch (err) {
    console.log("Error fetching get_dynamic_dashboard_data:", err);
    return { status: "error", message: err };
  }
};

export const getUploadTaskStatus = async (header, taskId) => {
  try {
    const res = await instance.get(
      `api/user-app/admin/user/get-background-task/?task_id=${taskId}`,
      { headers: header }
    );
    return res.data;
  } catch (err) {
    console.log("Error fetching task status:", err);
    return { status: "error", message: err };
  }
};