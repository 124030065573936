import React, { useState, useRef } from "react";
import {
  FaCloudUploadAlt,
  FaTrashAlt,
  FaRegFileAlt,
  FaUpload,
} from "react-icons/fa";
import toast from "react-hot-toast";
import "./Attachments.scss";

const Attachments = () => {
  const [uploadedFile, setUploadedFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleFileUpload = (files) => {
    if (files.length > 0) {
      const file = files[0];
      // Removed file type validation to allow any file type
      setUploadedFile(file);
    }
  };

  const handleFileInputChange = (e) => {
    handleFileUpload(e.target.files);
  };

  const formatFileSize = (size) => {
    if (size < 1024) return `${size} B`;
    else if (size < 1024 * 1024) return `${(size / 1024).toFixed(2)} KB`;
    else if (size < 1024 * 1024 * 1024)
      return `${(size / (1024 * 1024)).toFixed(2)} MB`;
    else return `${(size / (1024 * 1024 * 1024)).toFixed(2)} GB`;
  };

  const handleUploadTemplate = async () => {
    if (!uploadedFile) {
      toast.error("No file selected");
      return;
    }
    // Placeholder for file upload logic
    toast.success("Uploading file...");
  };

  const handleCancelUpload = () => {
    setUploadedFile(null);
    fileInputRef.current.value = null;
  };

  return (
    <div className="attachments">
      {!uploadedFile ? (
        <div className="attachments-body">
          <div className="attachments-body-icon">
            <FaCloudUploadAlt />
          </div>
          <div className="attachments-body-drag_text">
            Drag files to upload
          </div>
          <div className="attachments-body-subtext">or</div>
          <div
            className="attachments-body-button"
            onClick={() => fileInputRef.current.click()}
          >
            <div className="attachments-body-button-text">
              Browse Files
            </div>
          </div>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileInputChange}
          />
        </div>
      ) : (
        <div className="attachments-body-uploaded">
          <div className="attachments-body-uploaded-file">
            <div className="attachments-body-uploaded-file-icon">
              <FaRegFileAlt />
            </div>
            <div className="attachments-body-uploaded-file-info">
              <div className="attachments-body-uploaded-file-info-name">
                {uploadedFile.name}
              </div>
              <div className="attachments-body-uploaded-file-info-size">
                {formatFileSize(uploadedFile.size)}
              </div>
            </div>
          </div>
          <div className="attachments-body-uploaded-action">
            <div
              className="attachments-body-uploaded-action-upload_button"
              onClick={handleUploadTemplate}
            >
              <div className="attachments-body-uploaded-action-upload_button-icon">
                <FaUpload />
              </div>
              <div className="attachments-body-uploaded-action-upload_button-text">
                Upload
              </div>
            </div>
            <div
              className="attachments-body-uploaded-action-cancel_button"
              onClick={handleCancelUpload}
            >
              <div className="attachments-body-uploaded-action-cancel_button-icon">
                <FaTrashAlt />
              </div>
              <div className="attachments-body-uploaded-action-cancel_button-text">
                Remove
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Attachments;
