import React, { useState, useEffect } from "react";
import ButtonTabs from "../../../common/components/ButtonTabs";
import toast from "react-hot-toast";
import QuestionRenderer from "../../../common/components/Form/QuestionRenderer";
import "./UserIncidentForm.scss";
import {
  getFormSections,
  getFormSectionQuestions,
} from "../services/api.services";
import Loader from "../../../common/components/Loader";

const UserIncidentForm = ({ header, incidentId }) => {
  const [selectedTab, setSelectedTab] = useState(null);
  const [sectionData, setSectionData] = useState([]);
  const [sectionQuestionData, setSectionQuestionData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedAnswers, setSelectedAnswers] = useState({});

  useEffect(() => {
    fetchFormSections(incidentId);
  }, [incidentId]);

  useEffect(() => {
    const collectAnswers = (questionsList) => {
      let answers = {};
      questionsList.forEach((q) => {
        if (q.answer && q.answer.answer !== undefined) {
          answers[q.id] = q.answer.answer;
        }
        if (q.children) {
          if (Array.isArray(q.children)) {
            Object.assign(answers, collectAnswers(q.children));
          } else if (typeof q.children === "object") {
            Object.values(q.children).forEach((childItem) => {
              if (Array.isArray(childItem)) {
                Object.assign(answers, collectAnswers(childItem));
              } else if (typeof childItem === "object") {
                Object.values(childItem).forEach((childList) => {
                  if (Array.isArray(childList)) {
                    Object.assign(answers, collectAnswers(childList));
                  }
                });
              }
            });
          }
        }
      });
      return answers;
    };

    if (sectionQuestionData && sectionQuestionData.length > 0) {
      const initialAnswers = collectAnswers(sectionQuestionData);
      setSelectedAnswers(initialAnswers);
    } else {
      setSelectedAnswers({});
    }
  }, [sectionQuestionData]);

  const fetchFormSections = async (incidentId) => {
    try {
      const response = await getFormSections(header, incidentId);
      if (response.status === 200 && response.data?.results) {
        setSectionData(response.data.results);
        if (response.data.results.length > 0) {
          // Load the first section by default.
          handleSectionSelect(response.data.results[0]);
        }
      } else {
        toast.error("Failed to fetch sections: Invalid response format.");
      }
    } catch (error) {
      toast.error("Error fetching sections: " + error.message);
    }
  };

  const handleSectionSelect = async (section) => {
    setSelectedTab(section);
    setLoading(true);
    try {
      const response = await getFormSectionQuestions(header, section.id);
      if (response.status === 200 && response.data?.form_section?.questions) {
        setSectionQuestionData(response.data.form_section.questions);
      } else {
        toast.error("Failed to fetch questions: Invalid response format.");
      }
    } catch (error) {
      toast.error("Error fetching questions: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (questionId, selectedValue) => {
    toast.success("Selected value: " + selectedValue);
    // You can update the answers state here if needed.
  };

  return (
    <div className="user-incident-form">
      <ButtonTabs
        items={sectionData.map((section) => section.name)}
        activeTab={selectedTab?.name}
        onTabChange={(tabName) => {
          const selectedSection = sectionData.find(
            (section) => section.name === tabName
          );
          handleSectionSelect(selectedSection);
        }}
      />

      {/* Always render the content area */}
      <div className="user-incident-form-content">
        <div className="user-incident-form-content-body">
          <div className="user-incident-form-content-body-inner">
            {sectionQuestionData && sectionQuestionData.length > 0 ? (
              sectionQuestionData.map((question) => (
                <QuestionRenderer
                  key={question.id}
                  question={question}
                  selectedAnswers={selectedAnswers}
                  handleInputChange={handleInputChange}
                />
              ))
            ) : (
              <p>No questions available.</p>
            )}
          </div>
          <div className="user-incident-form-content-body-outer">
            <button className="primary-button">
              {/* You can toggle between Submit/Next here */}
              Submit
            </button>
          </div>
        </div>
      </div>

      {/* Loader overlay covers the whole form */}
      {loading && (
        <div className="incident-form-spinner">
          <Loader />
        </div>
      )}
    </div>
  );
};

export default UserIncidentForm;
